/**
 * Font Face
 */

// Libre Baskerville Regular
@font-face {
  font-family: 'Libre Baskerville';
  src: url('../fonts/LibreBaskerville/librebaskerville-regular-webfont.woff2') format('woff2'),
       url('../fonts/LibreBaskerville/librebaskerville-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

// Libre Baskerville Bold
@font-face {
  font-family: 'Libre Baskerville';
  src: url('../fonts/LibreBaskerville/librebaskerville-bold-webfont.woff2') format('woff2'),
       url('../fonts/LibreBaskerville/librebaskerville-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

// Libre Baskerville Regular Italic
@font-face {
  font-family: 'Libre Baskerville';
  src: url('../fonts/LibreBaskerville/librebaskerville-italic-webfont.woff2') format('woff2'),
       url('../fonts/LibreBaskerville/librebaskerville-italic-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

// Alegreya Sans Light
@font-face {
  font-family: 'Alegreya Sans';
  src: url('../fonts/AlegreyaSans/AlegreyaSans-Light.eot');
  src: url('../fonts/AlegreyaSans/AlegreyaSans-Light.eot?#iefix') format('embedded-opentype'),
       url('../fonts/AlegreyaSans/AlegreyaSans-Light.woff2') format('woff2'),
       url('../fonts/AlegreyaSans/AlegreyaSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

// Alegreya Sans Regular
@font-face {
  font-family: 'Alegreya Sans';
  src: url('../fonts/AlegreyaSans/AlegreyaSans-Regular.eot');
  src: url('../fonts/AlegreyaSans/AlegreyaSans-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/AlegreyaSans/AlegreyaSans-Regular.woff2') format('woff2'),
       url('../fonts/AlegreyaSans/AlegreyaSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

// Alegreya Sans Bold
@font-face {
  font-family: 'Alegreya Sans';
  src: url('../fonts/AlegreyaSans/AlegreyaSans-Bold.eot');
  src: url('../fonts/AlegreyaSans/AlegreyaSans-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/AlegreyaSans/AlegreyaSans-Bold.woff2') format('woff2'),
       url('../fonts/AlegreyaSans/AlegreyaSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}