.news {

  &__main {
    @include wrap;
  }

  &__list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;

    &-item {
      padding: 20px 0;
      width: 100%;
      @include mq($from: mobile) {
        padding: 20px;
        width: 50%;
      }
      @include mq($from: tablet) {
        width: 33.333%;
      }
    }
  }

}