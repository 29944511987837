.events {
  overflow-x: hidden;

  &__main {
    @include wrap(1128px);
  }

  &__list {

    

  }

}