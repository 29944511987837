.card-podcast {

  display: flex;
  padding: 30px 0;
  position: relative;

  @include mq($until: tablet) {
    .podcasts__list-item:nth-child(1) & {
      padding-top: 0;
    }
    .podcasts__list-item:not(:nth-child(1)) & {
      border-top: 1px solid $color-primary;
    }
  }

  @include mq($from: tablet) {
    .podcasts__list-item:nth-child(1) &,
    .podcasts__list-item:nth-child(2) & {
      padding-top: 0;
    }
    .podcasts__list-item:not(:nth-child(1)):not(:nth-child(2)) & {
      border-top: 1px solid $color-primary;
    }
  }

  &__left {
    width: 100px;
  }

  &__right {
    flex: 1;
    margin-left: 30px;
  }

  &__picture {
    position: relative;
    @include size(100px);
    @include img-object-fit;
  }

  &__time {
    font-family: $font-secondary;
    font-size: 16px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0;
  }

  &__type {
    margin: 0;
    font-family: $font-secondary;
    font-size: 20px;
  }

  &__title {
    font-size: 24px;
    margin-bottom: 0;

    a {
      text-decoration: none;
      &:after {
        content: "";
        @include overlay;
      }
    }
  }

}