/**
 * Placeholders
 * Usage:
 * @include placeholder(blue);
 */

@mixin placeholder($color: #000) {
  ::-webkit-input-placeholder {
    color: $color;
  }
  :-moz-placeholder {
    color: $color;
  }
  ::-moz-placeholder {
    color: $color;
  }
  :-ms-input-placeholder {
    color: $color;
  }
  ::-ms-input-placeholder {
    color: $color;
  }
}
