.contact {

  &__main {
    @include wrap(1060px);
  }

  &__head {

  }

  &__title {
    font-family: $font-primary;
    font-weight: 400;
    margin-bottom: 75px;
  }

  &__text {

  }

  &__newsletter {
    max-width: 580px;
    margin-left: auto;
    margin-top: 75px;
  }

  &__form {
    margin-top: 75px;
    max-width: 640px;
  }

}