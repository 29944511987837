.carousel {

  &__main {
    @include wrap;
  }

  &__head {
    max-width: 640px;
  }

  &__title {

  }

  &__content {
    position: relative;
  }

  &__list {

    margin: 40px -15px 0;
    display: flex;
    @include animate-list;

    &-item {
      position: relative;
      flex: 0 0 calc(100% - 60px);
      padding: 15px;

      @include mq($from: phablet) {
        flex: 0 0 calc(50% - 30px);
      }

      @include mq($from: desktop) {
        flex: 0 0 calc(33.333% - 20px);
      }

      @include mq($from: wide) {
        flex: 0 0 33.333%;
      }
    }

  }

  &__prev,
  &__next {
    @include reset-button-style;
    @include absolute(calc(50% - 35px),null,null,null);
    @include size(70px);
    border-radius: 50%;
    background-color: $color-primary;
    opacity: 0;

    &:after {
      content: "";
      @include overlay;
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: center;
    }

    .carousel__content:hover & {
      opacity: 1;

      &:hover {
        opacity: .7;
      }
    }

    @include mq($until: desktop) {
      display: none;
    }

    @include basic-transition(opacity, .25s, null, $ease-out-quad);

    &[disabled] {
      opacity: 0 !important;
    }
  }

  &__prev {
    left: -50px;

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 39.06 30.52'%3E%3Cpath fill='%23FFFFFF' d='M12.38.21h0A3.09,3.09,0,0,0,11.24,1Q8.14,4.7,5,8.4L4.1,9.52Q2.36,11.59.63,13.67A2.19,2.19,0,0,0,.2,16.12a6.12,6.12,0,0,0,.85,1.31Q5,22.13,9,26.8c.83,1,1.66,1.93,2.5,2.89a2.47,2.47,0,0,0,4.08-.56,2.45,2.45,0,0,0-.34-2.73q-2.79-3.33-5.56-6.68L8.14,17.88h28a3.9,3.9,0,0,0,.78-.07,2.72,2.72,0,0,0,2.11-2.44v-.11a2.53,2.53,0,0,0-1.85-2.4,3.76,3.76,0,0,0-1.05-.12h-28l7.12-8.56a2.55,2.55,0,0,0,.09-3.11A2.4,2.4,0,0,0,12.38.21Z'/%3E%3C/svg%3E");
    }
  }

  &__next {
    right: -50px;

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 39.06 30.52'%3E%3Cpath fill='%23FFFFFF' d='M26.68,30.32h0a3.2,3.2,0,0,0,1.14-.83L34,22.13,35,21l3.47-4.14a2.21,2.21,0,0,0,.43-2.46A6.12,6.12,0,0,0,38,13.09C35.37,10,32.72,6.85,30.07,3.73L27.58.83a2.49,2.49,0,0,0-4.09.57,2.43,2.43,0,0,0,.34,2.72l5.56,6.69,1.53,1.83h-28a3.89,3.89,0,0,0-.78.08A2.71,2.71,0,0,0,0,15.15v.12a2.52,2.52,0,0,0,1.85,2.39,3.8,3.8,0,0,0,1,.13h28L23.8,26.36a2.54,2.54,0,0,0-.09,3.1A2.41,2.41,0,0,0,26.68,30.32Z'/%3E%3C/svg%3E");
    }
  }

  &__button {
    text-align: center;
    margin-top: 50px;

    @include mq($until: tablet) {
      margin-top: 25px;
    }
  }

}