/**
 * Buttons
 */

input.btn,
button.btn {
  @include reset-button-style;
}

@mixin btn {
  position: relative;
  vertical-align: top;
  display: inline-block;
  text-decoration: none;

  font-family: $font-secondary;
  line-height: 20px;
  padding: 12px 30px;
  background-color: $color-tertiary;
  color: #006194;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  border-radius: 30px;

  @include basic-transition((background,color), .25s, null, $ease-out-quart);
  
  @include on-event {
    color: white;
    background-color: $color-primary;
  }

}

input.btn,
button.btn,
.btn {
  @include btn;

  @include on-event {
    .icon {
      &.angle-left { transform: translateX(-5px); }
      &.angle-right { transform: translateX(5px); }
    }
  }

  .icon {
    @include size(9px,16px);
    fill: $color-tertiary;
    vertical-align: top;
    transition: .3s transform $ease-out-quint;
    margin-top: 1px;

    &.angle-left { margin-right: 10px; }
    &.angle-right { margin-left: 10px; }
  }

  &-secondary {
    background-color: transparent;
    border: 2px solid $color-secondary;
    color: $color-secondary;
    padding: 13px 28px;

    @include on-event {
      background-color: $color-secondary;
      color: white;
    }
  }
}

.c-buttons {

  .article > & {
    margin: 40px 0;
  }
  
  .c-button {
    margin: 0 5px;
    display: inline-block;

    @include margin-first-last;
  }
}