.team {

  margin: 0;

  &__main {
    @include wrap(980px);
    border-bottom: 1px solid $color-primary;
    padding: 100px 0;
    @include mq($until: tablet) {
      padding: 75px 0;
    }
  }

  &__head {

  }

  &__title {
    font-family: $font-primary;
    font-weight: 400;
  }

  &__list {

    &-item {
      padding-top: 50px;
    }

    @include mq($from: tablet) {
      display: flex;
      flex-flow: row wrap;
      margin: 0 -20px;
      &-item {
        width: 50%;
        padding: 75px 20px 0;
      }
    }
  }

}