/**
 * Animate elements on scroll
 */

[data-animate] {
  transition: $scroll-transition;
  opacity: 0;
  transform: translateY($scroll-transform-base);

  &.is-animate {
    transform: translateY(0);
    opacity: 1;
  }
}