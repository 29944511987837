/**
 * Section
 */

section {
  margin: rem(100px) 0;

  @include mq($until: tablet) {
    margin: rem(60px) 0;
  }
}
