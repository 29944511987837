/**
 * Header
 */

.header {

  @include fixed(0,0,null,0);
  z-index: 19;
  background-color: rgba($body-background,.98);
  border-bottom: 1px solid $color-primary;

  & + .main {
    margin-top: 101px;

    @include mq($until: desktop) {
      margin-top: 80px;
    }

    @include mq($until: mobile) {
      margin-top: 71px;
    }
  }

  &__main {
    //@include wrap;
    padding: 10px 10px 10px 0;
    @include mq($from: mobile) {
      padding: 15px 15px 15px 0;
    }
    @include mq($from: desktop) {
      padding: 15px 25px 15px 0;
    }

    @include mq($from: 1100px) {
      padding: 25px 50px 25px 0;
    }

    display: flex;
    justify-content: space-between;
    
    .is-loaded & {
      @include basic-transition(padding, .25s, null, $ease-out-quart);
    }

    .is-small & {
      @include mq($from: mobile) {
        padding: 10px 15px 10px 0;
      }
      @include mq($from: desktop) {
        padding-right: 25px;
      }
      @include mq($from: 1100px) {
        padding-right: 50px;
      }
    }
    
  }

  &__logo {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateX(-5px);
  }

  &__navigation {
    display: flex;
    @include mq($until: desktop) {
      margin-left: auto;
      margin-right: 10px;
    }
  }

  &__cart {
    @include mq($from: desktop) {
      margin-left: 30px;
    }

    .btn-cart {
      @include reset-button-style;
      position: relative;
      font-family: $font-secondary;
      font-size: 24px;
      line-height: 1;
      @include size(57px,31px);
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 57 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M40.8704 30H16.1296C11.8632 30 8.06663 27.2932 6.67583 23.2599L1.28619 7.62995C0.166576 4.38307 2.57855 1 6.01305 1H50.9869C54.4214 1 56.8334 4.38307 55.7138 7.62995L50.3242 23.2599C48.9334 27.2932 45.1368 30 40.8704 30Z' stroke='%23008E94'/%3E%3C/svg%3E");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-top: 10px;
    }

    .snipcart-checkout-box {
      position: relative;
      font-family: $font-secondary;
      font-size: 24px;
      line-height: 1;
      @include size(45px,25px);
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M51.714 20.47L55 60H9l3.286-39.53h9.857v-6.588C22.143 8.424 26.556 4 32 4c5.444 0 9.857 4.424 9.857 9.882v6.589h9.857zM25.43 13.883v16.47h-3.286v-6.587H15.31l-2.737 32.94h38.856l-2.737-32.94h-6.834v6.588h-3.286v-16.47c0-3.634-2.947-6.589-6.571-6.589-3.624 0-6.571 2.955-6.571 6.588zm3.285 9.883V20.47h6.572v3.294h-6.572z' fill='%23008E94'/%3E%3C/svg%3E");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center left;
      margin-top: 11px;
      .snipcart-checkout {
        @include reset-button-style;
        @include overlay;
        @include size(100%);
        text-indent: -9999px;
        overflow: hidden;
        z-index: 1;
      }
      .snipcart-items-count {
        display: block;
        line-height: 1.15;
        @include overlay;
        text-align: right;
      }
    }

  }

  &__nav-button {

    display: none;

    @include mq($until: desktop) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

}