/**
 * Transitions
 * Usage:
   @include basic-transition(color,.2s,null,$ease-out-quad);
 */

@mixin basic-transition($properties: (all), $duration: $transition-duration, $delay: null, $easing: $easing) {
  $value: null;

  @each $prop in $properties {
    $prop-value: append((), $prop, space);
    $prop-value: append($prop-value, $duration, space);

    @if $delay != null {
      $prop-value: append($prop-value, $delay, space);
    }

    $prop-value: append($prop-value, $easing, space);

    $value: append($value, $prop-value, comma);
  }

  transition: $value;
}
