.breadcrumb {
  margin: 0;
  padding: 20px 0;

  &__main {
    @include wrap;
  }

  &__nav {

    font-family: $font-secondary;
    font-size: 20px;
    text-transform: lowercase;

    ol {
      @include list-reset;
      li {
        display: inline;
        position: relative;
        &:not(:last-child) {
          padding-right: 20px;
          margin-right: 15px;
          &:after {
            content: "";
            @include absolute(2px,0,0,null);
            width: 10px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 512'%3E%3Cpath d='M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z' fill='%23008E94'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
        }
        h1 {
          display: inline;
          font-size: 20px;
          line-height: $base-line-height;
          font-weight: 400;
        }
      }
    }
  }
}