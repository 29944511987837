/**
 * Animate List
 * Usage:
   @include animate-list;
 */

@mixin animate-list {
  
  & > * {
    transition: $scroll-transition;
    opacity: 0;
    transform: translateY($scroll-transform-base);
  }

  &.is-animate > * {
    transform: translateY(0);
    opacity: 1;

    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        transition-delay: $i * 0.15s;
      }
    }
  }

}
