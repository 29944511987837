.card-book {

  width: 200px;
  min-height: 375px;
  margin: auto;
  position: relative;

  &__tag {
    @include absolute(-50px,10px,null,null);
    @include size(100px);
    border-radius: 50%;
    background-color: rgba($color-primary,.8);
    z-index: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include basic-transition(opacity, .3s, null, $ease-out-quad);

    &--red {
      background-color: rgba($color-secondary,.8);
    }

    &--green {
      background-color: rgba($color-primary,.8);
    }

    &--yellow {
      background-color: rgba($color-tertiary,.8);
      p {
        color: $color-primary !important;
      }
    }

    .card-book:hover & {
      opacity: 0;
    }

    p {
      margin: 0;
      line-height: 1.2;
      font-family: $font-secondary;
      color: white;
      font-weight: 700;
    }
  }

  &__picture {
    margin-bottom: 30px;
    background-color: white;
    border: 1px solid rgba($color-primary,.3);
    //min-height: 292px;
    img {
      object-fit: cover;
      object-position: center;
      display: block;
    }
  }

  &__title {
    font-size: 24px;
    margin-bottom: 5px;

    .card-book__front & {
      @include mq($until: tablet) {
        display: none;
      }
    }
  }

  &__front {
    @include mq($from: tablet) {
      @include absolute(0,0,null,0);
    }
  }

  &__back {

    @include mq($from: tablet) {
      position: relative;
      min-height: 440px;
      z-index: 1;
      background-color: $color-primary;
      color: white;
      padding: 25px 20px;
      display: flex;
      flex-direction: column;
      opacity: 0;
      @include basic-transition(opacity, .3s, null, $ease-out-quad);

      .card-book--Essai &,
      .card-book--essai &,
      .card-book--revue & {
        background-color: $color-secondary;
      }

      .card-book:hover & {
        opacity: 1;
      }
    }
  }

  &__type {
    text-align: center;
    font-family: $font-secondary;
    font-size: 24px;
    margin-bottom: 10px;

    &:first-letter {
      text-transform: uppercase;
    }

    @include mq($until: tablet) {
      display: none;
    }
  }

  &__author {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 0;

    .card-book__front & {
      @include mq($until: tablet) {
        display: none;
      }
    }
  }

  &__text {
    font-size: 16px;
    margin-top: 15px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    overflow: hidden;
  }

  &__button {

    @include mq($until: tablet) {
      opacity: 0;
      @include overlay;
    }

    @include mq($from: tablet) {
      display: inline-block;
      background-color: $color-tertiary;
      text-decoration: none;
      padding: 5px 20px;
      border-radius: 30px;
      margin-top: auto;
      text-align: center;

      font-family: $font-secondary;
      font-weight: 700;
      font-size: 20px;

      @include on-event {
        color: $color-primary;
      }
    }

    &:after {
      content: "";
      @include overlay;
    }
  }

}