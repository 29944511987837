.footer {

  padding-top: 50px;
  border-top: 1px solid $color-primary;

  &--banner {
    padding-bottom: 80px;
  }

  &__main {
    @include wrap;
  }

  &__columns {
    @include mq($until: desktop) {
      text-align: center;
    }
    @include mq($from: desktop) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__logo {
    img {
      display: block;
      height: 80px;

      @include mq($until: desktop) {
        margin: 0 auto 40px;
      }
    }
  }

  &__social {
    font-family: $font-secondary;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 25px;

    ul {
      @include list-reset;
      li {
        margin: 0;
      }
    }

    a {
      text-decoration: none;
    }
  }

  &__text {
    strong {
      font-family: $font-secondary;
      font-size: 20px;
    }
  }

  &__newsletter {
    strong {
      font-family: $font-secondary;
      font-size: 20px;
    }
  }

  &__bottom {
    @include mq($from: 1200px) {      
      display: flex;
      justify-content: space-between;
    }
    @include mq($until: 1200px) {
      text-align: center;
    }
    padding: 30px 0;
    margin-top: 30px;
  }

  &__navigation {
    font-family: $font-secondary;
    font-weight: 700;
    flex: 1;

    @include mq($until: 1200px) {
      width: 100%;
      margin: auto;
    }
    @include mq($until: phablet) {
      margin-bottom: 30px;
    }
    ul {
      @include list-reset;
      @include mq($from: phablet) {
        display: flex;
        justify-content: center;
      }
      li {
        margin: 0;
        @include mq($from: phablet) {
          &:not(:first-child) {
            margin-left: 25px;
          }
        }
        a {
          text-decoration: none;
        }
      }
    }
  }

  &__info {
    p {
      font-size: 16px;
      margin: 2px 0;

    }
  }

}