/*
 * https://css-tricks.com/styling-a-select-like-its-2019/
 */

 .select-css {
  display: block;
  width: 100%;

  font-size: .9em;
  line-height: 25px;
  margin: 0;
  padding: 13px 15px;

  border: 1px solid $color-primary;
  background-color: white;
  
  outline: none;

  transition: background 0.3s $ease-out-quad, border 0.3s $ease-out-quint;

  font-family: $font-primary;
  color: $color-text;
  
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  background-color: white;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23008E94' viewBox='0 0 32.52 17.84'%3E%3Cpath d='M.29,4.85h0a4.18,4.18,0,0,0,1.1,1.51q3.57,3,7.15,6L9.88,13.5,14,17a3.2,3.2,0,0,0,3.52.56,6.86,6.86,0,0,0,1.53-1c3.13-2.64,6.25-5.3,9.37-7.95,1-.83,1.94-1.66,2.9-2.5a3.35,3.35,0,0,0,1.17-2.38A3.37,3.37,0,0,0,30.56.37,3.61,3.61,0,0,0,29,0a3.45,3.45,0,0,0-2.23.84L19.85,6.59l-3.55,3L5.8.81A3.54,3.54,0,0,0,1.47.67,3.42,3.42,0,0,0,.29,4.85Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 25px top 52%, 0 0;
  background-size: 12px;

  cursor: pointer;

  transition: box-shadow .25s $ease-out-quint;

  &:focus {
    outline: none;
  }

}

.select-css::-ms-expand {
  display: none;
}