/**
 * Header Navigation
 */

@include mq($from: desktop) {

  .header-navigation {

    & > nav {
      display: inline-block;
    }

    & > nav > ul {
      
      @include list-reset;
      display: inline-flex;

      & > li {
        position: relative;
        margin: 5px 25px;
        @include link-hover-anim($color-primary);
        @include margin-first-last;

        font-family: $font-secondary;

        font-size: 20px;
        font-weight: 700;

        @include mq($from: 1100px) {
          font-size: 24px;
        }

        & > a {
          text-transform: lowercase;
          display: inline-block;
          text-align: center;
          padding: 10px 0;
          text-decoration: none;
          line-height: 20px;
          color: $color-primary;

          @include on-event {
            color: rgba($color-primary,.7);
          }
          
        }

        &.menu-item-has-children {
          margin-right: 40px;
          & > a {
            position: relative;
            &:after {
              content: "";
              @include absolute(0,-20px,0,null);
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32.52 17.84'%3E%3Cpath fill='%23008E94' d='M.29,4.85h0a4.18,4.18,0,0,0,1.1,1.51q3.57,3,7.15,6L9.88,13.5,14,17a3.2,3.2,0,0,0,3.52.56,6.86,6.86,0,0,0,1.53-1c3.13-2.64,6.25-5.3,9.37-7.95,1-.83,1.94-1.66,2.9-2.5a3.35,3.35,0,0,0,1.17-2.38A3.37,3.37,0,0,0,30.56.37,3.61,3.61,0,0,0,29,0a3.45,3.45,0,0,0-2.23.84L19.85,6.59l-3.55,3L5.8.81A3.54,3.54,0,0,0,1.47.67,3.42,3.42,0,0,0,.29,4.85Z'/%3E%3C/svg%3E");
              background-repeat: no-repeat;
              background-position: center;
              @include size(10px,auto);

              transition: .3s transform $ease-in-out-back;
            }
          }

          &:hover {
            & > a {
              &:after {
                transform: rotate(180deg);
              }
            }
          }
        }
        
        & > a {
          
        }
        
        &:hover > a {
          color: rgba($color-primary,.7);
        }

      }

    }

    & > nav > ul > li > ul {

      @include list-reset;
      @include absolute(calc(100% - 1px),null,null,50%);
      text-align: left;

      padding: 20px 35px;
      z-index: 10;
      background-color: $body-background;
      border: 1px solid $color-primary;

      visibility: hidden;
      transform: translateX(-50%) translateY(10px);
      opacity: 0;
      transition: transform .3s $ease-out-quart, opacity .3s $ease-out-quart, .3s visibility 0s $ease-out-quad;

      li {
        position: relative;
        margin: 3px 0;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        font-size: .9em;
        font-weight: 600;
        white-space: nowrap;
        text-decoration: none;

        padding: 7px 0;
        @include link-hover-anim($color-text,1px);

        @include on-event {
          color: rgba($color-text,.7);
        }
      }
    }

    & > nav > ul > li:hover > ul {
      visibility: visible;
      transform: translateX(-50%) translateY(0);
      opacity: 1;
      transition: transform .3s .1s $ease-out-quart, opacity .3s .1s $ease-out-quart;
    }

  }

}