.card-news {

  position: relative;
  max-width: 275px;
  margin: auto;

  &__date {
    margin-bottom: 5px;
    font-family: $font-secondary;
    font-size: 22px;
  }

  &__title {
    font-size: 24px;
    margin-bottom: 15px;
    a {
      text-decoration: none;
      &:after {
        content: "";
        z-index: 1;
        @include overlay;
      }
    }
  }

  &__picture {
    @include aspect-ratio(4,3);
    @include img-object-fit;

    &:after {
      content: "";
      @include overlay;
      @include basic-transition(opacity, .3s, null, $ease-out-quad);

      .card-news--red & {
        background-color: rgba($color-secondary,.35);
      }

      .card-news--yellow & {
        background-color: rgba($color-tertiary,.35);
      }

      .card-news--green & {
        background-color: rgba($color-primary,.35);
      }

      .card-news:hover & {
        opacity: 0;
      }
    }
  }

  &__text {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 15px;
  }

}