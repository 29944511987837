.footer-banner {

  @include fixed(null,0,0,0);
  z-index: 10;
  background-color: $color-tertiary;
  padding: 20px;
  font-family: $font-secondary;
  font-size: 20px;
  line-height: 1.25;
  text-align: center;

  @include mq($until: tablet) {
    font-size: 16px;
    padding: 15px 20px;
  }

  p {
    margin: 0;
  }

}