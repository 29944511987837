/**
 * Header Navigation Mobile
 */

@include mq($until: desktop) {

  .header-navigation {

    @include fixed(0,0,0,0);
    z-index: 100;
    text-align: center;
    background-color: $body-background;
    transform: translateX(100%);

    transition: .5s transform $ease-in-out-quart;

    .open-menu & {
      transform: translateX(0);
    }

    & > nav {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      & > ul {

        @include list-reset;
        padding: 20px 0;

        & > li {

          transform: translateY(75px);
          opacity: 0;
          transition: opacity 0s .4s $ease-out-quad, transform 0s .4s $ease-out-quart;

          .open-menu & {
            transform: translateY(0);
            opacity: 1;
            transition: opacity .4s $ease-out-quad, transform .4s $ease-out-quart;

            @for $i from 1 through 20 {
              &:nth-child(#{$i}) {
                transition-delay: ($i + 10) * 0.05s;
              }
            }

          }

          & > a {
            display: inline-block;
            position: relative;
            font-family: $font-secondary;
            font-size: 24px;
            padding: 5px 0;
            text-decoration: none;
            font-weight: 700;
            text-align: center;
            text-transform: lowercase;
            @include on-event {
              color: $color-primary;
            }
          }

          &.current-menu-item > a {
            color: $color-primary;
            &:before {
              content: "";
              @include absolute(null,0,5px,0);
              height: 1px;
              background-color: $color-primary;
            }
          }

          &.menu-item-has-children {
            & > a:after {
              content: "";
              @include absolute(23px,-30px,null,null);
              @include size(12px,7px);
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32.52 17.84'%3E%3Cpath fill='%23008E94' d='M.29,4.85h0a4.18,4.18,0,0,0,1.1,1.51q3.57,3,7.15,6L9.88,13.5,14,17a3.2,3.2,0,0,0,3.52.56,6.86,6.86,0,0,0,1.53-1c3.13-2.64,6.25-5.3,9.37-7.95,1-.83,1.94-1.66,2.9-2.5a3.35,3.35,0,0,0,1.17-2.38A3.37,3.37,0,0,0,30.56.37,3.61,3.61,0,0,0,29,0a3.45,3.45,0,0,0-2.23.84L19.85,6.59l-3.55,3L5.8.81A3.54,3.54,0,0,0,1.47.67,3.42,3.42,0,0,0,.29,4.85Z'/%3E%3C/svg%3E");
              background-position: center;
              background-repeat: no-repeat;
              background-size: 100%;
              transition: .3s transform $ease-in-out-quart;
            }
            &[aria-expanded="true"] {
              & > a {
                &:after {
                  transform: rotate(180deg);
                }
              }
            }
          }

          & > ul {
            //display: none;
            @include list-reset;
            text-align: center;
            height: 0;
            overflow: hidden;
            @include basic-transition(height, .25s, null, $ease-out-quart);

            li {
              position: relative;
              padding: 0 20px;
            }

            a {
              display: block;
              text-decoration: none;
              font-size: 18px;
              line-height: 1.5;
              font-weight: 400;
              font-family: $font-secondary;
            }
          }

        }

      }

    }

  }

}