* {
  --color-default: #008E94;
  --color-icon: #008E94;
  --borderColor-input: #008E94;
  --borderColor-default: #008E94;
  --borderColor-input-hover: #008E94;
  --color-inputLabel: #008E94;

  --bgColor-default: #FDFFF9;
  --bgColor-alt: #FDFFF9;
  --bgColor-input: #FDFFF9;
  --bgColor-modal: #FDFFF9;

  --bgColor-badge: #008E94;
  --color-badge: #FFFFFF;
  --bgColor-badge-active: #DCDC00;
  --color-badge-active: #008E94;
  --borderColor-input-focus: #008E94;
}

.snipcart {
  font-family: $font-secondary;
  font-size: 20px;
  font-weight: 300;
  color: #2e3338;
  color: var(--color-default, #2e3338)
}

.snipcart * {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  color: inherit;
  background: 0 0;
  display: block;
  line-height: 1;
  list-style: none;
  quotes: none;
  border-collapse: collapse;
  border-spacing: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.snipcart__font--secondary,
.snipcart__font--subtitle,
.snipcart__font--subtitle-small {
  font-family: $font-secondary;
}

.snipcart__font--subtitle-small,
.snipcart__font--tiny {
  font-size: 18px;
  font-weight: 400;
}

.snipcart__font--std {
  font-size: 18px;
  line-height: 20px
}

.snipcart__font--large {
  font-size: 20px
}

.snipcart__box--badge,
.snipcart__font--subtitle,
.snipcart__font--xlarge {
  font-size: 22px
}

.snipcart__font--slim {
  font-weight: 400
}

.snipcart__font--regular {
  font-weight: 400
}

.snipcart__font--bold {
  font-weight: 500;
  line-height: 20px
}

.snipcart__font--black,
.snipcart__font--subtitle,
.snipcart__font--subtitle-small {
  font-weight: 600
}

.snipcart__font--subtitle {
  display: flex
}

.snipcart__font--subtitle-small {
  display: flex;
  text-transform: uppercase
}

.snipcart__font--blue {
  color: #1a4db3;
  color: var(--color-link, #1a4db3)
}

.snipcart__box {
  padding: 32px 16px;
  position: relative;
  margin-bottom: 10.66667px;
  background-color: #fff;
  background-color: var(--bgColor-default, #fff)
}

@media(min-width:1024px) {
  .snipcart__box {
    padding: 32px;
    margin-top: 0
  }
}

.snipcart__box--large {
  padding: 32px;
  margin-top: 0
}

.snipcart__box--no-margin {
  margin-bottom: 0
}

.snipcart__box--gray {
  background-color: #f7f7f8;
  background-color: var(--bgColor-alt, #f7f7f8)
}

.snipcart__box--slim {
  padding: 32px 32px 16px
}

@media(min-width:1024px) {
  .snipcart__box--slim {
    padding: 16px 32px
  }
}

.snipcart__box--slim--large {
  padding: 16px 32px
}

.snipcart__box--header {
  display: flex;
  justify-content: space-between;
  align-items: center
}

@media(min-width:1024px) {
  .snipcart__box--title {
    display: flex;
    justify-content: flex-start;
    align-items: center
  }
}

.snipcart__box--subtitle,
.snipcart__box--title--large {
  display: flex;
  justify-content: flex-start;
  align-items: center
}

.snipcart__box--content {
  padding: 10.66667px 0
}

.snipcart__box--badge {
  height: 40px;
  width: 40px;
  border-radius: 999px;
  border: 3px solid;
  color: #0d59f2;
  color: var(--color-badge, #0d59f2);
  border-color: transparent;
  border-color: var(--borderColor-badge, transparent);
  background-color: #fff;
  background-color: var(--bgColor-badge, #fff);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -48px;
  margin-bottom: 10.66667px
}

@media(min-width:1024px) {
  .snipcart__box--badge {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: -48px;
    margin-right: 8px
  }
}

.snipcart__box--badge--large {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -48px;
  margin-right: 8px
}

.snipcart__box--badge-disabled {
  color: #2e3338;
  color: var(--color-badge-disabled, #2e3338);
  border-color: #e3e6e8;
  border-color: var(--borderColor-badge-disabled, #e3e6e8);
  background-color: #f7f7f8;
  background-color: var(--bgColor-badge-disabled, #f7f7f8)
}

.snipcart__box--badge-highlight {
  color: #0d59f2;
  color: var(--color-badge-active, #0d59f2);
  border-color: transparent;
  border-color: var(--borderColor-badge-active, transparent);
  background-color: #d9f0fc;
  background-color: var(--bgColor-badge-active, #d9f0fc)
}

.snipcart-modal {
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  background-color: #f1f2f4;
  background-color: var(--bgColor-modal, #f1f2f4)
}

@supports(-webkit-overflow-scrolling:touch) {
  .snipcart-modal input,
  .snipcart-modal select,
  .snipcart-modal textarea {
    font-size: 20px
  }
}

.snipcart-modal__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
  z-index: 20;
  background-color: #f1f2f4;
  background-color: var(--bgColor-modal, #f1f2f4)
}

.snipcart-modal__overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  background-color: hsla(0, 0%, 100%, .7);
  background-color: var(--bgColor-modalVeil, hsla(0, 0%, 100%, .7))
}

.snipcart-modal__close {
  background-color: transparent;
  border: none
}

.snipcart-modal__close:focus {
  outline: 5px auto -webkit-focus-ring-color
}

.snipcart-modal__close:active {
  outline: none
}

.snipcart-modal__header {
  padding: 16px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: 100%
}

.snipcart-modal__header--fixed {
  padding: 16px;
  min-height: 80px
}

.snipcart-modal__header-sumary {
  cursor: pointer
}

.snipcart-modal__header-summary-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer
}

.snipcart-modal__close {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-secondary;
  color: #2e3338;
  color: var(--color-default, #2e3338);
  cursor: pointer
}

.snipcart-modal__close-icon {
  margin-right: 10px
}

.snipcart-modal__close-title {
  display: none;
  line-height: 1.4
}

@media(min-width:768px) {
  .snipcart-modal__close-title {
    display: inline;
    height: 20px
  }
}

.snipcart-modal__close-title--tablet-up {
  display: inline;
  height: 20px
}

.snipcart__actions--link {
  text-decoration: none;
  color: #1a4db3;
  color: var(--color-link, #1a4db3);
  font-family: $font-secondary;
  font-size: 18px;
  border: none;
  background-color: transparent;
  cursor: pointer
}

.snipcart__actions--link:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
  outline-width: 5px
}

.snipcart__notice--with-icon {
  display: flex;
  align-items: center
}

.snipcart__notice__icon {
  min-width: 20px;
  min-height: 20px
}

.snipcart__icon {
  width: 20px;
  height: 20px
}

.snipcart__icon--spin {
  animation: spin .7s linear 0s infinite
}

.snipcart__icon--angled {
  transform: rotateY(0deg) rotate(45deg)
}

.snipcart__icon--left {
  margin-right: 10px
}

.snipcart__icon--small {
  width: 16px;
  height: 16px
}

.snipcart__icon--default {
  width: 125px;
  height: auto
}

.snipcart__icon--std {
  width: 20px;
  height: 20px
}

.snipcart__icon--medium {
  width: 30px;
  height: 30px
}

.snipcart__icon--large {
  width: 40px;
  height: 40px
}

.snipcart__icon--xlarge {
  width: 125px;
  height: 125px
}

.snipcart__icon--brand {
  width: auto;
  height: 20px
}

.snipcart-overlay--disabled {
  position: absolute;
  background-color: #fff;
  background-color: var(--bgColor-default, #fff);
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .8
}

.snipcart-scrollbar {
  overflow-y: auto;
  overflow-x: hidden
}

.snipcart-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .3);
  border-radius: 4px
}

.snipcart-scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: 0
}

.snipcart-summary-fees {
  color: #2e3338;
  color: var(--color-default, #2e3338)
}

.snipcart-summary-fees__item {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 3.2px 0;
  position: relative;

  &.snipcart-summary-fees__total {
    font-weight: 700;
  }
}

.snipcart-summary-fees__item:not(:last-child) {
  margin-bottom: 8px
}

.snipcart-summary-fees__title {
  display: flex;
  align-items: center;
  margin: 0
}

.snipcart-summary-fees__amount {
  text-align: right
}

.snipcart-summary-fees__notice {
  margin-top: 24px;
  margin-bottom: 20px;
}

.snipcart-summary-fees__discounts-icon {
  margin-left: 8px
}

.snipcart-summary-fees__discount-container {
  display: flex
}

.snipcart-summary-fees__discount-container:not(:last-of-type) {
  margin-bottom: 8px
}

.snipcart-summary-fees__discount-name {
  white-space: nowrap
}

.snipcart-summary-fees__discount-amount-saved {
  margin-left: auto;
  padding-left: 16px
}

.snipcart-summary-fees__tax-notice {
  margin-left: 8px
}

.snipcart-summary-fees__tool-tip {
  margin-top: 28px
}

.snipcart-summary-fees--reverse {
  display: flex;
  flex-direction: column-reverse
}

.snipcart-cart {
  list-style-type: none;
  margin: 0;
  padding: 0
}

.snipcart-cart__featured-payment-methods-container {
  margin-top: 32px
}

.snipcart-cart__featured-payment-methods-title {
  display: flex;
  align-items: center;
  padding: 3.2px;
  margin-top: -1em;
  margin-bottom: 0;
  background-color: #f1f2f4;
  background-color: var(--bgColor-modal, #f1f2f4)
}

.snipcart-cart__content {
  margin: 0 auto;
  background-color: #f1f2f4;
  background-color: var(--bgColor-modal, #f1f2f4)
}

.snipcart-cart__edit-header {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.snipcart-cart__flash-message {
  margin-bottom: 16px
}

.snipcart-cart__footer {
  padding: 0 16px
}

@media(min-width:1024px) {
  .snipcart-cart__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10.66667px
  }
}

.snipcart-cart__footer--large {
  display: flex;
  justify-content: space-between;
  margin-top: 10.66667px
}

@media(min-width:768px) and (max-width:1024px) {
  .snipcart-cart__footer {
    display: block;
    margin-top: 0
  }
}

.snipcart__media-query--tablet .snipcart-cart__footer {
  display: block;
  margin-top: 0
}

@media(min-width:768px) {
  .snipcart-cart__footer {
    padding: 0
  }
}

.snipcart-cart__footer--tablet-up {
  padding: 0
}

@media(min-width:1024px) {
  .snipcart-cart__footer-col {
    width: 33.33333%
  }
}

.snipcart-cart__footer-col--large {
  width: 33.33333%
}

@media(min-width:768px) and (max-width:1024px) {
  .snipcart-cart__footer-col {
    width: 100%
  }
}

.snipcart__media-query--tablet .snipcart-cart__footer-col {
  width: 100%
}

@media(min-width:768px) and (max-width:1024px) {
  .snipcart-cart__footer-discount-box {
    padding-bottom: 20px
  }
}

.snipcart__media-query--tablet .snipcart-cart__footer-discount-box {
  padding-bottom: 20px
}

.snipcart-cart__discount-box {
  margin-bottom: 40px
}

.snipcart-cart__secondary-header {
  display: flex;
  flex: 1;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f8;
  background-color: var(--bgColor-alt, #f7f7f8);
  height: 100%;
  max-height: 64px;
  min-height: 64px;
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid $color-primary;
}

.snipcart-cart__secondary-header-title {
  flex: 1
}

@media(min-width:1024px) {
  .snipcart-cart__secondary-header {
    min-height: 102px;
    padding-left: 64px;
    padding-right: 32px
  }
}

.snipcart-cart__secondary-header--large {
  min-height: 102px;
  padding-left: 64px;
  padding-right: 32px
}

.snipcart-cart__footer-buttons {
  text-align: center
}

.snipcart-cart__footer-buttons .snipcart-button-link,
.snipcart-cart__footer-buttons .snipcart-button-primary {
  margin-top: 16px
}

.snipcart-cart--edit .snipcart-cart__footer-col {
  width: 100%
}

.snipcart-cart--edit .snipcart-cart__discount-box {
  margin-bottom: 40px
}

.snipcart-cart--edit .snipcart-cart__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  background-color: var(--bgColor-default, #fff);
  flex: 1;
  margin: 0
}

.snipcart-cart--edit .snipcart-cart__footer {
  background-color: #fff;
  background-color: var(--bgColor-default, #fff);
  width: 100%;
  margin: 0 auto;
  padding: 32px;
  display: block
}

@media(min-width:1024px) {
  .snipcart-cart--edit .snipcart-cart__footer {
    padding: 16px 64px
  }
}

.snipcart-cart--edit .snipcart-cart__footer--large {
  padding: 16px 64px
}

.snipcart-cart--edit {
  display: flex;
  flex-direction: column;
  max-width: 540px;
  background-color: #fff;
  background-color: var(--bgColor-default, #fff);
  height: 100%
}

.snipcart-cart-header {
  background-color: #f1f2f4;
  background-color: var(--bgColor-modal, #f1f2f4);
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 400;
  top: 0;
  width: 100%;
  margin-bottom: 16px;
  font-size: 20px;
}

@media(min-width:768px) {
  .snipcart-cart-header {
    width: 80%;
    margin: 0 auto 16px
  }
}

.snipcart-cart-header--tablet-up {
  width: 80%;
  margin: 0 auto 16px
}

@media(min-width:1024px) {
  .snipcart-cart-header {
    padding-right: 0;
    padding-left: 0;
    z-index: auto;
    height: auto;
    position: relative;
    padding: 32px 16px
  }
}

.snipcart-cart-header--large {
  padding-right: 0;
  padding-left: 0;
  z-index: auto;
  height: auto;
  position: relative;
  padding: 32px 16px
}

.snipcart-cart-header__close-button {
  flex: 1;
  justify-content: flex-start
}

.snipcart-cart-header__title {
  flex: 1;
  text-align: center
}

.snipcart-cart-header__options {
  flex: 1;
  display: flex;
  justify-content: flex-end
}

.snipcart-cart-header__option {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-end
}

.snipcart-cart-header__option:not(:last-child) {
  margin-right: 16px
}

.snipcart-cart-header__count,
.snipcart-cart-header__sign-in,
.snipcart-cart-header__sign-out {
  cursor: pointer
}

.snipcart-cart-header__customer-account {
  margin-right: 24px;
  cursor: pointer
}

.snipcart-cart-header__icon {
  margin-right: 5px
}

.snipcart-cart-header .snipcart-modal__close-icon,
.snipcart-cart-header__icon {
  color: #0aa7f5;
  color: var(--color-icon, #0aa7f5)
}

.snipcart-cart-header--fixed {
  position: fixed
}

.snipcart-empty-cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  color: #8f99a3;
  color: var(--color-alt, #8f99a3)
}

.snipcart-empty-cart .snipcart-button-secondary {
  margin-top: 16px
}

.snipcart-empty-cart__title {
  height: 70%;
  display: flex;
  align-items: center
}

.snipcart-custom-field-wrapper__label {
  margin-bottom: 8px
}

.snipcart-custom-field-wrapper__label .required__asterisk {
  color: #e85a4a;
  color: var(--color-error, #e85a4a);
  display: inline-block
}

@media(min-width:1024px) {
  .snipcart-dropdown-custom-field--standard {
    width: 33.33333%
  }
  .snipcart-dropdown-custom-field--medium {
    width: 66.66667%
  }
  .snipcart-dropdown-custom-field--large {
    width: 100%
  }
}

.snipcart-dropdown-custom-field--large--standard {
  width: 33.33333%
}

.snipcart-dropdown-custom-field--large--medium {
  width: 66.66667%
}

.snipcart-dropdown-custom-field--large--large {
  width: 100%
}

@media(min-width:768px) and (max-width:1024px) {
  .snipcart-dropdown-custom-field--large,
  .snipcart-dropdown-custom-field--medium,
  .snipcart-dropdown-custom-field--standard {
    width: 100%
  }
}

.snipcart__media-query--tablet .snipcart-dropdown-custom-field--large,
.snipcart__media-query--tablet .snipcart-dropdown-custom-field--medium,
.snipcart__media-query--tablet .snipcart-dropdown-custom-field--standard {
  width: 100%
}

.snipcart-textarea-custom-field {
  height: 80px;
  width: 100%;
  background-color: #fff;
  background-color: var(--bgColor-input, #fff);
  border: 1px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-input, #e3e6e8);
  padding: 8px
}

.snipcart-item-custom-fields {
  width: 100%;
  flex-grow: 1
}

@media(min-width:1024px) {
  .snipcart-item-custom-fields {
    display: flex;
    flex-wrap: wrap
  }
}

.snipcart-item-custom-fields--large {
  display: flex;
  flex-wrap: wrap
}

@media(min-width:768px) and (max-width:1024px) {
  .snipcart-item-custom-fields {
    display: block
  }
}

.snipcart__media-query--tablet .snipcart-item-custom-fields {
  display: block
}

.snipcart-item-custom-fields__field {
  margin-bottom: 10px
}

@media(min-width:1024px) {
  .snipcart-item-custom-fields__field {
    padding-right: 20px
  }
}

.snipcart-item-custom-fields__field--large {
  padding-right: 20px
}

@media(min-width:768px) and (max-width:1024px) {
  .snipcart-item-custom-fields__field {
    padding-right: 0
  }
}

.snipcart__media-query--tablet .snipcart-item-custom-fields__field {
  padding-right: 0
}

.snipcart-cart--edit .snipcart-item-custom-fields {
  display: block
}

.snipcart-cart--edit .snipcart-item-custom-fields__field {
  padding-right: 0;
  width: 100%
}

.snipcart-item-description {
  max-width: 500px;
  line-height: 20px
}

.snipcart-typeahead__select {
  position: relative
}

.snipcart-typeahead__suggestions {
  position: relative;
  max-height: 100vh;
  height: 100vh;
  margin: 0;
  margin-top: -1px;
  padding: 0;
  list-style-type: none;
  z-index: 1;
  width: 100%;
  cursor: pointer;
  border: 1px solid;
  color: #2e3338;
  color: var(--color-input-focus, #2e3338);
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-focus, #0aa7f5);
  background-color: #fff;
  background-color: var(--bgColor-input-focus, #fff);
  overflow-y: auto
}

@media(min-width:1024px) {
  .snipcart-typeahead__suggestions {
    position: absolute;
    max-height: auto;
    height: auto;
    top: 100%;
    max-height: 300px;
    overflow-y: auto
  }
}

.snipcart-typeahead__suggestions--large {
  position: absolute;
  max-height: auto;
  height: auto;
  top: 100%;
  max-height: 300px;
  overflow-y: auto
}

.snipcart-typeahead__typeahead__suggestion--highlight {
  font-weight: 600
}

.snipcart-typeahead__suggestion {
  margin: 0;
  padding: 8px 16px
}

.snipcart-typeahead__suggestion--active {
  background-color: #d9f0fc;
  background-color: var(--bgColor-input-autofill, #d9f0fc)
}

.snipcart-typeahead__suggestion--loading {
  color: #8f99a3;
  color: var(--color-alt, #8f99a3);
  text-align: center
}

.snipcart-typeahead--invalid {
  border: 1px solid
}

@keyframes slideIn-data-v-00c4f9d2 {
  0% {
    transform: translateX(110%)
  }
  to {
    transform: translateX(0)
  }
}

@keyframes spin-data-v-00c4f9d2 {
  0% {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(1turn)
  }
}

@keyframes slideDown-data-v-00c4f9d2 {
  0% {
    height: 0
  }
  to {
    height: 100vh
  }
}

@keyframes fadeIn-data-v-00c4f9d2 {
  0% {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@keyframes popUp-data-v-00c4f9d2 {
  0% {
    opacity: 0;
    transform: scale(.35)
  }
  to {
    opacity: 1;
    transform: scale(1)
  }
}

.snipcart-transition--slide-in-enter-active[data-v-00c4f9d2] {
  animation: slideIn-data-v-00c4f9d2 .5s
}

.snipcart-transition--slide-in-leave-active[data-v-00c4f9d2] {
  animation: slideIn-data-v-00c4f9d2 .3s reverse
}

.snipcart-transition--popup-enter-active[data-v-00c4f9d2] {
  animation: popUp-data-v-00c4f9d2 .2s
}

.snipcart-transition--popup-leave-active[data-v-00c4f9d2] {
  animation: popUp-data-v-00c4f9d2 .2s reverse
}

.snipcart-form__field--plan[data-v-00c4f9d2] {
  flex-grow: 0;
  flex-shrink: 0
}

.snipcart-form__field--plan .snipcart-form__select[data-v-00c4f9d2],
.snipcart-item-plans {
  width: 100%
}

@media(min-width:1024px) {
  .snipcart-item-plans {
    display: flex;
    flex-wrap: wrap
  }
}

.snipcart-item-plans--large {
  display: flex;
  flex-wrap: wrap
}

@media(min-width:768px) and (max-width:1024px) {
  .snipcart-item-plans {
    display: block
  }
}

.snipcart__media-query--tablet .snipcart-item-plans {
  display: block
}

.snipcart-item-plans .snipcart-form__field--plan {
  margin-bottom: 10px
}

@media(min-width:1024px) {
  .snipcart-item-plans .snipcart-form__field--plan {
    padding-right: 20px
  }
}

.snipcart-item-plans .snipcart-form__field--plan--large {
  padding-right: 20px
}

@media(min-width:768px) and (max-width:1024px) {
  .snipcart-item-plans .snipcart-form__field--plan {
    padding-right: 0
  }
}

.snipcart__media-query--tablet .snipcart-item-plans .snipcart-form__field--plan {
  padding-right: 0
}

.snipcart-cart--edit .snipcart-item-plans {
  display: block
}

.snipcart-cart--edit .snipcart-item-plans__field {
  padding-right: 0;
  width: 100%
}

.snipcart-item-plans .snipcart-form__field--plan--billed-on {
  display: flex;
  flex-direction: column
}

.snipcart-item-plans .snipcart-form__field--plan--billed-on .snipcart-form__field--plan__readonly {
  display: flex;
  align-items: center;
  flex-grow: 1
}

.snipcart-item-line {
  background-color: #fff;
  background-color: var(--bgColor-default, #fff);

  .snipcart-cart--opened & {
    border: 1px solid $color-primary;
  }
}

.snipcart-item-line__container {
  padding: 32px;
  display: flex;
  margin: 0 16px 24px
}

@media(min-width:768px) {
  .snipcart-item-line__container {
    margin: 0 0 24px
  }
}

.snipcart-item-line__container--tablet-up {
  margin: 0 0 24px
}

.snipcart-item-line__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px
}

@media(min-width:1024px) {
  .snipcart-item-line__header {
    margin-bottom: 0
  }
}

.snipcart-item-line__header--large {
  margin-bottom: 0
}

.snipcart-item-line__header__title {
  padding: 0;
  margin: 0
}

.snipcart-item-line__actions {
  color: #e85a4a;
  color: var(--color-error, #e85a4a)
}

.snipcart-item-line__quantity {
  min-width: 25%
}

@media(min-width:1024px) {
  .snipcart-item-line__quantity {
    min-width: 240px
  }
}

.snipcart-item-line__quantity--large {
  min-width: 240px
}

@media(min-width:768px) and (max-width:1024px) {
  .snipcart-item-line__quantity {
    width: auto
  }
}

.snipcart__media-query--tablet .snipcart-item-line__quantity {
  width: auto
}

.snipcart-item-line__body {
  width: 100%
}

@media(min-width:768px) and (max-width:1024px) {
  .snipcart-item-line__body {
    display: block
  }
}

.snipcart__media-query--tablet .snipcart-item-line__body {
  display: block
}

.snipcart-item-line__product {
  width: 100%
}

.snipcart-item-line__variants {
  padding-top: 16px
}

@media(min-width:1024px) {
  .snipcart-item-line__variants {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 8px
  }
}

.snipcart-item-line__variants--large {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 8px
}

.snipcart-item-line__variants>div:first-child {
  display: flex;
  flex-direction: column;
  flex-grow: 1
}

.snipcart-item-line__variants .snipcart-item-plans {
  margin-bottom: 16px
}

.snipcart-item-line__info {
  width: 100%;
  padding: 8px 0
}

.snipcart-item-line__image {
  width: auto;
  max-height: 100%
}

@media(min-width:1024px) {
  .snipcart-item-line__image {
    width: 120px
  }
}

.snipcart-item-line__image--large {
  width: 120px
}

.snipcart-item-line__media {
  margin: 0;
  padding: 0;
  min-width: 120px;
  margin-right: 32px
}

.snipcart-item-line__media--small {
  display: none;
  min-width: auto;
  height: 32px;
  margin: 0;
  width: auto
}

.snipcart-item-line--cart-edit .snipcart-item-line__edit {
  max-width: 40px;
  width: auto
}

.snipcart-item-line--cart-edit .snipcart-item-line__variants {
  display: block
}

.snipcart-item-line--cart-edit .snipcart-item-line__title {
  flex: 1;
  text-align: left;
}

.snipcart-item-line--cart-edit .snipcart-item-line__product {
  width: 100%
}

.snipcart-item-line--cart-edit .snipcart-item-line__image {
  max-width: 40px;
  width: auto
}

.snipcart-item-line--cart-edit {
  box-shadow: none;
  padding: 32px 0;
  border-bottom: 1px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-default, #e3e6e8);
  margin: 0 32px
}

@media(min-width:1024px) {
  .snipcart-item-line--cart-edit {
    margin: 0 64px
  }
}

.snipcart-item-line--cart-edit--large {
  margin: 0 64px
}

.snipcart-item-line:last-child {
  margin-bottom: 0
}

.snipcart-item-line .snipcart-form__field--plan__readonly {
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 50px
}

.snipcart-item-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 32px
}

.snipcart-cart--edit .snipcart-item-list {
  background-color: #fff;
  background-color: var(--bgColor-default, #fff);
  margin-bottom: 0
}

.snipcart-item-quantity__label {
  color: #2e3338;
  color: var(--color-inputLabel, #2e3338);
  margin-bottom: 8px;
  display: inline-block
}

.snipcart-item-quantity__quantity {
  display: flex;
  align-items: center;
  line-height: normal;
  height: 52px;
  color: #2e3338;
  color: var(--color-input, #2e3338);
  transition: .2s ease-out;
  transition-property: color, border-color, background-color;
  padding-left: 8px;
  padding-right: 8px;
  background-color: #fff;
  background-color: var(--bgColor-input, #fff);
  justify-content: space-between;
  border: 1px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-input, #e3e6e8);
  width: 50%;
  max-width: 160px
}

.snipcart-item-quantity__quantity:hover {
  color: #2e3338;
  color: var(--color-input-hover, #2e3338);
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-hover, #0aa7f5);
  background-color: #fff;
  background-color: var(--bgColor-input-hover, #fff)
}

.snipcart-item-quantity__quantity:focus-within {
  color: #2e3338;
  color: var(--color-input-focus, #2e3338);
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-focus, #0aa7f5);
  background-color: #fff;
  background-color: var(--bgColor-input-focus, #fff);
  box-shadow: 0 5px 10px -3px rgba(10, 167, 245, .3);
  box-shadow: var(--shadow-input-focus, 0 5px 10px -3px rgba(10, 167, 245, .3))
}

.snipcart-item-quantity__quantity--disabled {
  justify-content: center
}

.snipcart-item-quantity__quantity-price-container {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.snipcart-checkout__content {
  max-width: 968px;
  margin-left: auto;
  margin-right: auto
}

.snipcart-checkout__step-cols {
  justify-content: space-between;
  padding: 10.66667px 0
}

.snipcart-checkout__step-col,
.snipcart-checkout__step-cols {
  display: flex;
  align-items: flex-start
}

.snipcart-checkout__step-title {
  display: none
}

.snipcart-change-password__form {
  box-shadow: none !important
}

.snipcart-change-password__form .snipcart-submit {
  margin-top: 16px
}

.snipcart-change-password__title {
  margin-bottom: 16px;
  margin-top: 16px
}

.snipcart-change-password__title:first-child {
  margin-top: 0
}

.snipcart-change-password__box {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
  max-width: 540px
}

.snipcart-change-password__box .snipcart__close-icon {
  color: #0aa7f5;
  color: var(--color-icon, #0aa7f5)
}

.snipcart-payment-methods-list-item__label__name {
  margin-left: 5px
}

.snipcart-customer-details__payment-methods {
  min-height: 150px
}

.snipcart-update-payment-method-form__form.snipcart-form {
  box-shadow: none
}

.snipcart-update-payment-method-form__container {
  margin-top: 10.66667px
}

.snipcart-update-payment-method-form iframe {
  border: none;
  width: 100%
}

.snipcart-orders-link {
  justify-content: flex-start
}

.snipcart-update-customer-payment-method__form {
  box-shadow: none !important
}

.snipcart-update-customer-payment-method__title {
  margin-bottom: 16px;
  margin-top: 16px
}

.snipcart-update-customer-payment-method__title:first-child {
  margin-top: 0
}

.snipcart-update-customer-payment-method__box {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
  max-width: 540px
}

.snipcart-update-customer-payment-method__box .snipcart__close-icon {
  color: #0aa7f5;
  color: var(--color-icon, #0aa7f5)
}

.snipcart-update-customer-payment-method__submit-button {
  margin-top: 16px
}

.snipcart-guest-checkout__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px
}

.snipcart-guest-checkout__or {
  z-index: 99;
  padding-left: 24px;
  padding-right: 24px;
  text-align: center;
  margin-left: auto;
  margin-right: auto
}

.snipcart-guest-checkout__or,
.snipcart-guest-checkout__seperator {
  position: relative;
  background-color: #f7f7f8;
  background-color: var(--bgColor-alt, #f7f7f8)
}

.snipcart-guest-checkout__seperator {
  margin-top: -5px;
  height: 1px;
  width: 100%
}

.snipcart-guest-checkout__link {
  text-align: center;
  color: #1a4db3;
  color: var(--color-link, #1a4db3)
}

.snipcart-orders-link {
  justify-content: left;
  margin-bottom: 20px
}

.snipcart-orders-link .snipcart-modal__close-label {
  margin-left: .4em
}

.snipcart-edit-customer-address__form {
  box-shadow: none !important
}

.snipcart-edit-customer-address__form .snipcart-submit {
  margin-top: 16px
}

.snipcart-edit-customer-address__title {
  margin-bottom: 16px;
  margin-top: 16px
}

.snipcart-edit-customer-address__title:first-child {
  margin-top: 0
}

.snipcart-edit-customer-address__box {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
  max-width: 540px
}

.snipcart-edit-customer-address__box .snipcart__close-icon {
  color: #0aa7f5;
  color: var(--color-icon, #0aa7f5)
}

.snipcart-forgot-password__box {
  width: 100%;
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
  padding: 32px
}

.snipcart-forgot-password .snipcart-forgot-password__form {
  box-shadow: none
}

.snipcart-forgot-password .snipcart-forgot-password__form .snipcart-submit {
  margin-top: 16px
}

.snipcart-forgot-password__title {
  margin-bottom: 16px
}

.snipcart-forgot-password__instructions {
  margin-bottom: 16px;
  line-height: 24px
}

.snipcart-forgot-password__submit-button {
  margin-top: 16px
}

.snipcart-register__title {
  margin-bottom: 16px
}

.snipcart-register__register {
  margin-bottom: 32px
}

.snipcart-register__box {
  width: 100%;
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
  padding: 32px
}

.snipcart-register .snipcart-register__form {
  box-shadow: none
}

.snipcart-register .snipcart-register__form .snipcart-submit {
  margin-top: 16px
}

.snipcart-register__register-link {
  text-decoration: none;
  display: inline;
  color: #1a4db3;
  color: var(--color-link, #1a4db3);
  cursor: pointer
}

.snipcart-reset-password__box {
  width: 100%;
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
  padding: 32px
}

.snipcart-reset-password .snipcart-reset-password__form {
  box-shadow: none
}

.snipcart-reset-password .snipcart-reset-password__form .snipcart-submit {
  margin-top: 16px
}

.snipcart-reset-password__title,
.snipcart-signin__title {
  margin-bottom: 16px
}

.snipcart-signin__register {
  margin-bottom: 32px
}

.snipcart-signin__box {
  width: 100%;
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
  padding: 32px
}

.snipcart-signin .snipcart-signin__form {
  box-shadow: none
}

.snipcart-signin .snipcart-signin__form .snipcart-submit {
  margin-top: 16px
}

.snipcart-signin__register-link {
  display: inline;
  cursor: pointer
}

.snipcart-signin__forgot-password,
.snipcart-signin__register-link {
  text-decoration: none;
  color: #1a4db3;
  color: var(--color-link, #1a4db3)
}

.snipcart-signin__forgot-password {
  text-align: right;
  margin-top: 8px
}

.snipcart-payment-details {
  padding: 12px;
  border-radius: 8px
}

.snipcart-payment-details__header {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.snipcart-payment-details__card {
  display: flex;
  align-items: center;
  margin-top: 12px
}

.snipcart-subscription-details__plan {
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer
}

.snipcart-subscription-details__plan--readonly {
  cursor: default;
  opacity: .7
}

.snipcart-subscription-details__plan--readonly .snipcart-subscription-details__plan__selector {
  display: none
}

.snipcart-subscription-details__plan--readonly .snipcart-form-radio__label {
  cursor: default
}

.snipcart-subscription-details__plan--selected {
  opacity: 1
}

.snipcart-subscription-details__plan--selected .snipcart-subscription-details__plan__selector {
  display: flex
}

.snipcart-subscription-details__plan__image {
  width: 64px;
  height: 64px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  border-radius: 4px
}

.snipcart-subscription-details__plan__infos {
  margin-left: 16px;
  flex: 1
}

.snipcart-subscription-details__plan__infos:first-child {
  margin-left: 0
}

.snipcart-subscription-details__plan__name {
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4
}

.snipcart-subscription-details__plan__recurrence {
  line-height: 1.4
}

.snipcart-subscription-details__plan .snipcart-form-radio__label {
  width: 24px
}

.snipcart-subscription-details__plan .snipcart-form-radio+.snipcart-form__label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  border-radius: 99px;
  width: 18px;
  min-width: 18px;
  height: 18px;
  background-color: #fff;
  background-color: var(--bgColor-input, #fff);
  border: 1px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-input, #e3e6e8)
}

.snipcart-subscription-details__plan .snipcart-form-radio.snipcart-form-radio--checked+.snipcart-form__label:before {
  background-color: #1a4db3;
  background-color: var(--bgColor-buttonPrimary, #1a4db3);
  border: none
}

.snipcart-subscription-details__plan .snipcart-form-radio.snipcart-form-radio--checked+.snipcart-form__label:after {
  content: url(../img/checkmark.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  z-index: 99;
  position: absolute;
  left: -3px;
  top: -3px;
  width: 24px;
  height: 24px;
  border: none
}

.snipcart-subscription-details {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 64px
}

@media(min-width:1024px) {
  .snipcart-subscription-details .snipcart__box--title {
    justify-content: space-between;
    width: 100%
  }
}

.snipcart-subscription-details .snipcart__box--title--large {
  justify-content: space-between;
  width: 100%
}

.snipcart-subscription-details .snipcart-subscription__header {
  padding-bottom: 0 !important
}

.snipcart-subscription-details .snipcart__box--title {
  width: 100%;
  display: flex;
  justify-content: space-between
}

.snipcart-subscription-details .snipcart__box--title .snipcart__icon--left {
  color: #0aa7f5;
  color: var(--color-icon, #0aa7f5)
}

.snipcart-subscription-details .snipcart__box--header {
  padding-bottom: 16px
}

.snipcart-subscription-details .snipcart-subscription-details__content div>p {
  margin-top: 10.66667px
}

.snipcart-subscription-details .snipcart-subscription-details__content div>p:first-child {
  margin-top: 0
}

.snipcart-subscription-details .snipcart-subscription-details__content div>p span:first-child {
  padding-right: 10.66667px
}

.snipcart-subscription-details .snipcart-subscription-details__content>.snipcart__box {
  padding-top: 0
}

.snipcart-subscription-details .snipcart-subscription-details__content span {
  display: inline-block
}

.snipcart-subscription-details .snipcart__box {
  margin-bottom: 0;
  padding: 32px
}

.snipcart-subscription-details .snipcart-subscription-details__plans {
  margin-top: 32px
}

.snipcart-subscription-details .snipcart-subscription-details__plans h2 {
  padding-bottom: 16px
}

.snipcart-subscription-details .snipcart-subscription-details__plans .snipcart-subscription-details__plan {
  border-bottom: 1px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-default, #e3e6e8)
}

.snipcart-subscription-details .snipcart-subscription-details__plans .snipcart-subscription-details__plan:last-child {
  border-bottom: none
}

.snipcart-subscription-details .snipcart-subscription-details__items {
  padding-top: 16px;
  padding-bottom: 16px
}

.snipcart-subscription-details .snipcart-subscription-details__items h2 {
  padding-bottom: 16px
}

.snipcart-subscription-details .snipcart-subscription-details__items .snipcart-cart-summary-expanded-item {
  padding-top: 16px;
  padding-bottom: 16px
}

.snipcart-subscription-details .snipcart-subscription-details__buttons {
  display: flex;
  width: 100%;
  justify-content: center
}

.snipcart-subscription-details .snipcart-subscription-details__buttons .snipcart-subscription-details__button {
  max-width: 50%;
  flex-grow: 1;
  text-align: center;
  margin-left: 16px
}

.snipcart-subscription-details .snipcart-subscription-details__buttons .snipcart-subscription-details__button:first-child {
  margin-left: 0
}

.snipcart-subscription-details .snipcart-subscription-details__buttons .snipcart-subscription-details__button--grow-0 {
  flex-grow: 0
}

.snipcart-subscription-details .snipcart-subscription-details__order {
  background-color: #f7f7f8;
  background-color: var(--bgColor-alt, #f7f7f8)
}

.snipcart-subscription-details .snipcart-subscription-details__order>div {
  border-bottom: 1px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-default, #e3e6e8);
  padding-top: 32px;
  padding-bottom: 32px
}

.snipcart-subscription-details .snipcart-subscription-details__order>div:last-child {
  border-bottom: none;
  padding-bottom: 0
}

.snipcart-subscription-details .snipcart-subscription-details__order>div:first-child {
  padding-top: 0
}

.snipcart-subscription-details .snipcart-subscription-details__order .snipcart-subscription-details__billing h3,
.snipcart-subscription-details .snipcart-subscription-details__order .snipcart-subscription-details__shipping h3 {
  margin-top: 16px;
  margin-bottom: 10.66667px
}

.snipcart-subscription-details .snipcart-subscription-details__order .snipcart-subscription-details__billing .snipcart-columns,
.snipcart-subscription-details .snipcart-subscription-details__order .snipcart-subscription-details__shipping .snipcart-columns {
  display: flex;
  flex-wrap: wrap
}

.snipcart-subscription-details .snipcart-subscription-details__order .snipcart-subscription-details__billing .snipcart-columns>div,
.snipcart-subscription-details .snipcart-subscription-details__order .snipcart-subscription-details__shipping .snipcart-columns>div {
  flex-shrink: 0;
  flex-basis: 100%
}

@media(min-width:1024px) {
  .snipcart-subscription-details .snipcart-subscription-details__order .snipcart-subscription-details__billing .snipcart-columns>div,
  .snipcart-subscription-details .snipcart-subscription-details__order .snipcart-subscription-details__shipping .snipcart-columns>div {
    padding-left: 10.66667px;
    padding-right: 10.66667px;
    flex: 1
  }
  .snipcart-subscription-details .snipcart-subscription-details__order .snipcart-subscription-details__billing .snipcart-columns>div:first-child,
  .snipcart-subscription-details .snipcart-subscription-details__order .snipcart-subscription-details__shipping .snipcart-columns>div:first-child {
    padding-left: 0
  }
  .snipcart-subscription-details .snipcart-subscription-details__order .snipcart-subscription-details__billing .snipcart-columns>div:last-child,
  .snipcart-subscription-details .snipcart-subscription-details__order .snipcart-subscription-details__shipping .snipcart-columns>div:last-child {
    padding-right: 0
  }
}

.snipcart-subscription-details .snipcart-subscription-details__order .snipcart-subscription-details__billing .snipcart-columns>div--large,
.snipcart-subscription-details .snipcart-subscription-details__order .snipcart-subscription-details__shipping .snipcart-columns>div--large {
  padding-left: 10.66667px;
  padding-right: 10.66667px;
  flex: 1
}

.snipcart-subscription-details .snipcart-subscription-details__order .snipcart-subscription-details__billing .snipcart-columns>div--large:first-child,
.snipcart-subscription-details .snipcart-subscription-details__order .snipcart-subscription-details__shipping .snipcart-columns>div--large:first-child {
  padding-left: 0
}

.snipcart-subscription-details .snipcart-subscription-details__order .snipcart-subscription-details__billing .snipcart-columns>div--large:last-child,
.snipcart-subscription-details .snipcart-subscription-details__order .snipcart-subscription-details__shipping .snipcart-columns>div--large:last-child {
  padding-right: 0
}

.snipcart-order__box {
  border: 1px solid $color-primary;
  border-bottom: 0;
}

.snipcart-order__details-title,
.snipcart-order__title {
  margin-bottom: 24px
}

.snipcart-order__title svg {
  color: #0d59f2;
  color: var(--color-badge, #0d59f2)
}

.snipcart-order__card {
  display: flex;
  align-items: center
}

.snipcart-order__step-title {
  display: block;
  margin-bottom: 16px
}

.snipcart-order__footer {
  margin-bottom: 32px
}

.snipcart-order__details {
  margin-bottom: 32px;
  margin: 0 16px 24px
}

@media(min-width:1024px) {
  .snipcart-order__details {
    width: 100%;
    max-width: 566px;
    margin: 0 auto
  }
}

.snipcart-order__details--large {
  width: 100%;
  max-width: 566px;
  margin: 0 auto
}

.snipcart-order__invoice-number {
  margin-top: 10.66667px;
  display: flex
}

.snipcart-order__invoice-number--highlight {
  margin-left: .4em;
  color: #1a4db3;
  color: var(--color-link, #1a4db3)
}

.snipcart-base-button {
  padding: 16px;
  border: 1px solid;
  border-radius: 4px;
  cursor: pointer;
  transition: .2s ease-out;
  transition-property: color, border-color, background-color, box-shadow;
  width: 100%
}

.snipcart-base-button__wrapper {
  display: flex;
  align-items: center;
  justify-content: center
}

.snipcart-base-button__label {
  flex-grow: 1;
  padding: 0 16px;
  font-family: $font-secondary;
  font-size: 18px;
  font-weight: 500
}

.snipcart-base-button__icon {
  grid-area: icon;
  height: 20px;
  width: 20px
}

.snipcart-base-button:focus,
.snipcart-base-button:focus-visible {
  outline: none
}

.snipcart-base-button.is-icon-left .snipcart-base-button__label {
  margin-right: 20px
}

.snipcart-base-button.is-icon-right .snipcart-base-button__label {
  margin-left: 20px
}

.snipcart-base-button.is-fit-content {
  width: auto
}

.snipcart-base-button.is-fit-content .snipcart-base-button__label {
  margin: 0
}

.snipcart-base-button.is-icon-left .snipcart-base-button__wrapper {
  flex-direction: row-reverse
}

.snipcart-base-button .slide-up-enter-active,
.snipcart-base-button .slide-up-leave-active {
  transition: 80ms cubic-bezier(.75, 0, .25, 1);
  transition-property: transform, opacity
}

.snipcart-base-button .slide-up-enter {
  transform: translateY(50%);
  opacity: 0
}

.snipcart-base-button .slide-up-enter-to,
.snipcart-base-button .slide-up-leave {
  transform: translateY(0);
  opacity: 1
}

.snipcart-base-button .slide-up-leave-to {
  transform: translateY(-50%);
  opacity: 0
}

.snipcart-button-primary {
  color: #fff;
  color: var(--color-buttonPrimary, #fff);
  border-color: transparent;
  border-color: var(--borderColor-buttonPrimary, transparent);
  background-color: #1a4db3;
  background-color: var(--bgColor-buttonPrimary, #1a4db3)
}

.snipcart-button-primary:hover {
  color: #fff;
  color: var(--color-buttonPrimary-hover, #fff);
  border-color: transparent;
  border-color: var(--borderColor-buttonPrimary-hover, transparent);
  background-color: #0d59f2;
  background-color: var(--bgColor-buttonPrimary-hover, #0d59f2)
}

.snipcart-button-primary:focus-visible {
  color: #fff;
  color: var(--color-buttonPrimary-focus, #fff);
  border-color: transparent;
  border-color: var(--borderColor-buttonPrimary-focus, transparent);
  background-color: #1a4db3;
  background-color: var(--bgColor-buttonPrimary-focus, #1a4db3);
}

.snipcart-button-primary:active {
  color: #fff;
  color: var(--color-buttonPrimary-active, #fff);
  border-color: var(--borderColor-buttonPrimary-active, );
  background-color: #1a4db3;
  background-color: var(--bgColor-buttonPrimary-active, #1a4db3);
}

.snipcart-button-primary[disabled] {
  box-shadow: none;
  cursor: not-allowed
}

.snipcart-button-primary.is-loading,
.snipcart-button-primary[disabled] {
  color: #e3e6e8;
  color: var(--color-buttonPrimary-disabled, #e3e6e8);
  border-color: transparent;
  border-color: var(--borderColor-buttonPrimary-disabled, transparent);
  background-color: #8f99a3;
  background-color: var(--bgColor-buttonPrimary-disabled, #8f99a3)
}

.snipcart-button-primary.is-success {
  color: #fff;
  color: var(--color-buttonPrimary-success, #fff);
  border-color: var(--borderColor-buttonPrimary-success, );
  background-color: #0b652f;
  background-color: var(--bgColor-buttonPrimary-success, #0b652f)
}

.snipcart-button-primary.is-error {
  color: #fff;
  color: var(--color-buttonPrimary-error, #fff);
  border-color: var(--borderColor-buttonPrimary-error, );
  background-color: #9e2215;
  background-color: var(--bgColor-buttonPrimary-error, #9e2215)
}

.snipcart-button-secondary {
  color: #133986;
  color: var(--color-buttonSecondary, #133986);
  border-color: transparent;
  border-color: var(--borderColor-buttonSecondary, transparent);
  background-color: #e7f6fe;
  background-color: var(--bgColor-buttonSecondary, #e7f6fe)
}

.snipcart-button-secondary:hover {
  color: #1a4db3;
  color: var(--color-buttonSecondary-hover, #1a4db3);
  border-color: transparent;
  border-color: var(--borderColor-buttonSecondary-hover, transparent);
  background-color: #ecf8fe;
  background-color: var(--bgColor-buttonSecondary-hover, #ecf8fe);
}

.snipcart-button-secondary:focus-visible {
  color: #133986;
  color: var(--color-buttonSecondary-focus, #133986);
  border-color: transparent;
  border-color: var(--borderColor-buttonSecondary-focus, transparent);
  background-color: #ecf8fe;
  background-color: var(--bgColor-buttonSecondary-focus, #ecf8fe);
}

.snipcart-button-secondary:active {
  color: #133986;
  color: var(--color-buttonSecondary-active, #133986);
  border-color: var(--borderColor-buttonSecondary-active, );
  background-color: #e7f6fe;
  background-color: var(--bgColor-buttonSecondary-active, #e7f6fe);
}

.snipcart-button-secondary[disabled] {
  box-shadow: none;
  cursor: not-allowed
}

.snipcart-button-secondary.is-loading,
.snipcart-button-secondary[disabled] {
  color: #8f99a3;
  color: var(--color-buttonSecondary-disabled, #8f99a3);
  border-color: transparent;
  border-color: var(--borderColor-buttonSecondary-disabled, transparent);
  background-color: #f1f2f4;
  background-color: var(--bgColor-buttonSecondary-disabled, #f1f2f4)
}

.snipcart-button-secondary.is-success {
  color: #29c768;
  color: var(--color-buttonSecondary-success, #29c768);
  border-color: transparent;
  border-color: var(--borderColor-buttonSecondary-success, transparent);
  background-color: #e2f5e0;
  background-color: var(--bgColor-buttonSecondary-success, #e2f5e0)
}

.snipcart-button-secondary.is-error {
  color: #e85a4a;
  color: var(--color-buttonSecondary-error, #e85a4a);
  border-color: transparent;
  border-color: var(--borderColor-buttonSecondary-error, transparent);
  background-color: #fceae8;
  background-color: var(--bgColor-buttonSecondary-error, #fceae8)
}

.snipcart-button-danger {
  color: #9e2215;
  color: var(--color-buttonDanger, #9e2215);
  border-color: transparent;
  border-color: var(--borderColor-buttonDanger, transparent);
  background-color: #fceae8;
  background-color: var(--bgColor-buttonDanger, #fceae8)
}

.snipcart-button-danger:hover {
  color: #e85a4a;
  color: var(--color-buttonDanger-hover, #e85a4a);
  border-color: transparent;
  border-color: var(--borderColor-buttonDanger-hover, transparent);
  background-color: #fdefed;
  background-color: var(--bgColor-buttonDanger-hover, #fdefed);
  box-shadow: 0 10px 4px -8px rgba(0, 0, 0, .25);
  box-shadow: var(--shadow-buttonDanger-hover, 0 10px 4px -8px rgba(0, 0, 0, .25))
}

.snipcart-button-danger:focus-visible {
  color: #9e2215;
  color: var(--color-buttonDanger-focus, #9e2215);
  border-color: transparent;
  border-color: var(--borderColor-buttonDanger-focus, transparent);
  background-color: #fceae8;
  background-color: var(--bgColor-buttonDanger-focus, #fceae8);
  box-shadow: 0 0 6px 2px #e85a4a;
  box-shadow: var(--shadow-buttonDanger-focus, 0 0 6px 2px #e85a4a)
}

.snipcart-button-danger:active {
  color: #9e2215;
  color: var(--color-buttonDanger-active, #9e2215);
  border-color: var(--borderColor-buttonDanger-active, );
  background-color: #fceae8;
  background-color: var(--bgColor-buttonDanger-active, #fceae8);
  box-shadow: none;
  box-shadow: var(--shadow-buttonDanger-active, none)
}

.snipcart-button-danger[disabled] {
  box-shadow: none;
  cursor: not-allowed
}

.snipcart-button-danger.is-loading,
.snipcart-button-danger[disabled] {
  color: #8f99a3;
  color: var(--color-buttonDanger-disabled, #8f99a3);
  border-color: transparent;
  border-color: var(--borderColor-buttonDanger-disabled, transparent);
  background-color: #f1f2f4;
  background-color: var(--bgColor-buttonDanger-disabled, #f1f2f4)
}

.snipcart-button-danger.is-success {
  color: #29c768;
  color: var(--color-buttonDanger-success, #29c768);
  border-color: transparent;
  border-color: var(--borderColor-buttonDanger-success, transparent);
  background-color: #fceae8;
  background-color: var(--bgColor-buttonDanger-success, #fceae8)
}

.snipcart-button-danger.is-error {
  color: #e85a4a;
  color: var(--color-buttonDanger-error, #e85a4a);
  border-color: transparent;
  border-color: var(--borderColor-buttonDanger-error, transparent);
  background-color: #fceae8;
  background-color: var(--bgColor-buttonDanger-error, #fceae8)
}

.snipcart-button-link {
  display: inline-block;
  font-family: $font-secondary;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  padding: 2px 0;
  text-decoration: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid;
  color: #1a4db3;
  color: var(--color-link, #1a4db3);
  border-color: currentColor;
  border-color: var(--borderColor-link, currentColor);
  background-color: none;
  background-color: var(--bgColor-link, none);
  transition: .2s ease-out;
  transition-property: color, border-color, background-color, box-shadow
}

.snipcart-button-link:focus,
.snipcart-button-link:focus-visible {
  outline: none
}

.snipcart-button-link:hover {
  color: #0d59f2;
  color: var(--color-link-hover, #0d59f2);
  border-color: currentColor;
  border-color: var(--borderColor-link-hover, currentColor);
  background-color: none;
  background-color: var(--bgColor-link-hover, none)
}

.snipcart-button-link:focus-visible {
  color: #1a4db3;
  color: var(--color-link-focus, #1a4db3);
  border-color: currentColor;
  border-color: var(--borderColor-link-focus, currentColor);
  background-color: #d9f0fc;
  background-color: var(--bgColor-link-focus, #d9f0fc);
  box-shadow: 0 6px 4px -3px rgba(10, 167, 245, .5);
  box-shadow: var(--shadow-link-focus, 0 6px 4px -3px rgba(10, 167, 245, .5))
}

.snipcart-button-link:active {
  color: #1a4db3;
  color: var(--color-link-active, #1a4db3);
  border-color: currentColor;
  border-color: var(--borderColor-link-active, currentColor);
  background-color: none;
  background-color: var(--bgColor-link-active, none)
}

.snipcart-button-icon {
  width: 20px;
  height: 20px;
  box-sizing: content-box;
  padding: 8px;
  cursor: pointer;
  border-radius: 100%;
  transition: .2s ease-out;
  transition-property: color, border-color, background-color, box-shadow
}

.snipcart-button-icon:focus,
.snipcart-button-icon:focus-visible {
  outline: none
}

.snipcart-button-icon.is-small {
  padding: 2px
}

.snipcart-button-icon.is-primary {
  color: #fff;
  color: var(--color-buttonPrimary, #fff);
  border-color: transparent;
  border-color: var(--borderColor-buttonPrimary, transparent);
  background-color: #1a4db3;
  background-color: var(--bgColor-buttonPrimary, #1a4db3)
}

.snipcart-button-icon.is-primary:hover {
  color: #fff;
  color: var(--color-buttonPrimary-hover, #fff);
  border-color: transparent;
  border-color: var(--borderColor-buttonPrimary-hover, transparent);
  background-color: #0d59f2;
  background-color: var(--bgColor-buttonPrimary-hover, #0d59f2);
  box-shadow: 0 10px 4px -8px rgba(0, 0, 0, .5);
  box-shadow: var(--shadow-buttonPrimary-hover, 0 10px 4px -8px rgba(0, 0, 0, .5))
}

.snipcart-button-icon.is-primary:focus-visible {
  color: #fff;
  color: var(--color-buttonPrimary-focus, #fff);
  border-color: transparent;
  border-color: var(--borderColor-buttonPrimary-focus, transparent);
  background-color: #1a4db3;
  background-color: var(--bgColor-buttonPrimary-focus, #1a4db3);
  box-shadow: 0 0 6px 2px #0aa7f5;
  box-shadow: var(--shadow-buttonPrimary-focus, 0 0 6px 2px #0aa7f5)
}

.snipcart-button-icon.is-primary:active {
  color: #fff;
  color: var(--color-buttonPrimary-active, #fff);
  border-color: var(--borderColor-buttonPrimary-active, );
  background-color: #1a4db3;
  background-color: var(--bgColor-buttonPrimary-active, #1a4db3);
  box-shadow: none;
  box-shadow: var(--shadow-buttonPrimary-active, none)
}

.snipcart-button-icon.is-primary[disabled] {
  box-shadow: none;
  cursor: not-allowed
}

.snipcart-button-icon.is-primary.is-loading,
.snipcart-button-icon.is-primary[disabled] {
  color: #e3e6e8;
  color: var(--color-buttonPrimary-disabled, #e3e6e8);
  border-color: transparent;
  border-color: var(--borderColor-buttonPrimary-disabled, transparent);
  background-color: #8f99a3;
  background-color: var(--bgColor-buttonPrimary-disabled, #8f99a3)
}

.snipcart-button-icon.is-primary.is-success {
  color: #fff;
  color: var(--color-buttonPrimary-success, #fff);
  border-color: var(--borderColor-buttonPrimary-success, );
  background-color: #0b652f;
  background-color: var(--bgColor-buttonPrimary-success, #0b652f)
}

.snipcart-button-icon.is-primary.is-error {
  color: #fff;
  color: var(--color-buttonPrimary-error, #fff);
  border-color: var(--borderColor-buttonPrimary-error, );
  background-color: #9e2215;
  background-color: var(--bgColor-buttonPrimary-error, #9e2215)
}

.snipcart-button-icon.is-secondary {
  color: #133986;
  color: var(--color-buttonSecondary, #133986);
  border-color: transparent;
  border-color: var(--borderColor-buttonSecondary, transparent);
  background-color: #e7f6fe;
  background-color: var(--bgColor-buttonSecondary, #e7f6fe)
}

.snipcart-button-icon.is-secondary:hover {
  color: #1a4db3;
  color: var(--color-buttonSecondary-hover, #1a4db3);
  border-color: transparent;
  border-color: var(--borderColor-buttonSecondary-hover, transparent);
  background-color: #ecf8fe;
  background-color: var(--bgColor-buttonSecondary-hover, #ecf8fe);
  box-shadow: 0 10px 4px -8px rgba(0, 0, 0, .2);
  box-shadow: var(--shadow-buttonSecondary-hover, 0 10px 4px -8px rgba(0, 0, 0, .2))
}

.snipcart-button-icon.is-secondary:focus-visible {
  color: #133986;
  color: var(--color-buttonSecondary-focus, #133986);
  border-color: transparent;
  border-color: var(--borderColor-buttonSecondary-focus, transparent);
  background-color: #ecf8fe;
  background-color: var(--bgColor-buttonSecondary-focus, #ecf8fe);
  box-shadow: 0 0 6px 1px rgba(10, 167, 245, .6);
  box-shadow: var(--shadow-buttonSecondary-focus, 0 0 6px 1px rgba(10, 167, 245, .6))
}

.snipcart-button-icon.is-secondary:active {
  color: #133986;
  color: var(--color-buttonSecondary-active, #133986);
  border-color: var(--borderColor-buttonSecondary-active, );
  background-color: #e7f6fe;
  background-color: var(--bgColor-buttonSecondary-active, #e7f6fe);
  box-shadow: none;
  box-shadow: var(--shadow-buttonSecondary-active, none)
}

.snipcart-button-icon.is-secondary[disabled] {
  box-shadow: none;
  cursor: not-allowed
}

.snipcart-button-icon.is-secondary.is-loading,
.snipcart-button-icon.is-secondary[disabled] {
  color: #8f99a3;
  color: var(--color-buttonSecondary-disabled, #8f99a3);
  border-color: transparent;
  border-color: var(--borderColor-buttonSecondary-disabled, transparent);
  background-color: #f1f2f4;
  background-color: var(--bgColor-buttonSecondary-disabled, #f1f2f4)
}

.snipcart-button-icon.is-secondary.is-success {
  color: #29c768;
  color: var(--color-buttonSecondary-success, #29c768);
  border-color: transparent;
  border-color: var(--borderColor-buttonSecondary-success, transparent);
  background-color: #e2f5e0;
  background-color: var(--bgColor-buttonSecondary-success, #e2f5e0)
}

.snipcart-button-icon.is-secondary.is-error {
  color: #e85a4a;
  color: var(--color-buttonSecondary-error, #e85a4a);
  border-color: transparent;
  border-color: var(--borderColor-buttonSecondary-error, transparent);
  background-color: #fceae8;
  background-color: var(--bgColor-buttonSecondary-error, #fceae8)
}

.snipcart-button-icon.is-danger {
  color: #9e2215;
  color: var(--color-buttonDanger, #9e2215);
  border-color: transparent;
  border-color: var(--borderColor-buttonDanger, transparent);
  background-color: #fceae8;
  background-color: var(--bgColor-buttonDanger, #fceae8)
}

.snipcart-button-icon.is-danger:hover {
  color: #e85a4a;
  color: var(--color-buttonDanger-hover, #e85a4a);
  border-color: transparent;
  border-color: var(--borderColor-buttonDanger-hover, transparent);
  background-color: #fdefed;
  background-color: var(--bgColor-buttonDanger-hover, #fdefed);
}

.snipcart-button-icon.is-danger:focus-visible {
  color: #9e2215;
  color: var(--color-buttonDanger-focus, #9e2215);
  border-color: transparent;
  border-color: var(--borderColor-buttonDanger-focus, transparent);
  background-color: #fceae8;
  background-color: var(--bgColor-buttonDanger-focus, #fceae8);
}

.snipcart-button-icon.is-danger:active {
  color: #9e2215;
  color: var(--color-buttonDanger-active, #9e2215);
  border-color: var(--borderColor-buttonDanger-active, );
  background-color: #fceae8;
  background-color: var(--bgColor-buttonDanger-active, #fceae8);
}

.snipcart-button-icon.is-danger[disabled] {
  box-shadow: none;
  cursor: not-allowed
}

.snipcart-button-icon.is-danger.is-loading,
.snipcart-button-icon.is-danger[disabled] {
  color: #8f99a3;
  color: var(--color-buttonDanger-disabled, #8f99a3);
  border-color: transparent;
  border-color: var(--borderColor-buttonDanger-disabled, transparent);
  background-color: #f1f2f4;
  background-color: var(--bgColor-buttonDanger-disabled, #f1f2f4)
}

.snipcart-button-icon.is-danger.is-success {
  color: #29c768;
  color: var(--color-buttonDanger-success, #29c768);
  border-color: transparent;
  border-color: var(--borderColor-buttonDanger-success, transparent);
  background-color: #fceae8;
  background-color: var(--bgColor-buttonDanger-success, #fceae8)
}

.snipcart-button-icon.is-danger.is-error {
  color: #e85a4a;
  color: var(--color-buttonDanger-error, #e85a4a);
  border-color: transparent;
  border-color: var(--borderColor-buttonDanger-error, transparent);
  background-color: #fceae8;
  background-color: var(--bgColor-buttonDanger-error, #fceae8)
}

.snipcart-card-product {
  display: flex;
  align-items: center;
  padding: 12px
}

.snipcart-card-product__image {
  width: 64px;
  height: 64px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  border-radius: 4px
}

.snipcart-card-product__infos {
  margin-left: 16px
}

.snipcart-card-product__name {
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4
}

.snipcart-card-product__price-ctn {
  display: flex
}

.snipcart-card-product__quantity {
  line-height: 1.4
}

.snipcart-card-product__by {
  margin: 0 .4em;
  line-height: 1.4
}

.snipcart-card-product__price {
  line-height: 1.4
}

#snipcart-billing-form .snipcart-input--readonly {
  display: flex;
  align-items: center;
  line-height: normal;
  height: 52px;
  border: 1px solid;
  color: #2e3338;
  color: var(--color-input, #2e3338);
  border-color: var(--borderColor-input, #e3e6e8);
  background-color: #fff;
  background-color: var(--bgColor-input, #fff);
  transition: .2s ease-out;
  transition-property: color, border-color, background-color;
  color: #8f99a3;
  color: var(--color-input-disabled, #8f99a3);
  border-color: #e3e6e8;
  border-color: var(--borderColor-input-disabled, #e3e6e8);
  background-color: #f1f2f4;
  background-color: var(--bgColor-input-disabled, #f1f2f4);
  padding-left: 16px;
  padding-right: 16px
}

#snipcart-billing-form .snipcart-input--readonly:hover {
  color: #2e3338;
  color: var(--color-input-hover, #2e3338);
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-hover, #0aa7f5);
  background-color: #fff;
  background-color: var(--bgColor-input-hover, #fff)
}

#snipcart-billing-form .snipcart-input--readonly:focus-within {
  color: #2e3338;
  color: var(--color-input-focus, #2e3338);
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-focus, #0aa7f5);
  background-color: #fff;
  background-color: var(--bgColor-input-focus, #fff);
  box-shadow: 0 5px 10px -3px rgba(10, 167, 245, .3);
  box-shadow: var(--shadow-input-focus, 0 5px 10px -3px rgba(10, 167, 245, .3))
}

#snipcart-billing-form .snipcart-input--readonly:focus,
#snipcart-billing-form .snipcart-input--readonly:hover {
  color: #8f99a3;
  color: var(--color-input-disabled, #8f99a3);
  border-color: #e3e6e8;
  border-color: var(--borderColor-input-disabled, #e3e6e8);
  background-color: #f1f2f4;
  background-color: var(--bgColor-input-disabled, #f1f2f4)
}

.snipcart-billing-completed__step-title {
  display: none
}

.snipcart-billing-completed__header {
  margin-bottom: 24px
}

.snipcart-billing-completed .snipcart-checkout-step__icon,
.snipcart-billing-completed__title .snipcart__icon--left {
  color: #0aa7f5;
  color: var(--color-icon, #0aa7f5)
}

.snipcart-billing-completed__slide-transition--enter-active {
  animation: fadeIn .3s
}

.snipcart-billing-completed__slide-transition--enter-leave {
  animation: fadeIn .3s reverse
}

.snipcart-billing-completed--titled .snipcart-billing-completed__step-title {
  display: block;
  margin-bottom: 16px
}

.snipcart-billing-completed--titled .snipcart-billing-completed__step-icon {
  display: none
}

.snipcart-payment-card-form iframe {
  border: none;
  width: 100%
}

.snipcart-payment-card-form__error {
  margin-top: 24px
}

.snipcart-payment-card-form__container {
  position: relative
}

.snipcart-payment-card-authentication__container iframe {
  width: 100%;
  height: 0
}

.snipcart-payment-card-authentication__container--visible iframe {
  height: 400px
}

.snipcart-payment .snipcart__box--title {
  margin-bottom: 16px
}

.snipcart-payment__no-payment {
  margin-top: 12px;
  margin-bottom: 16px
}

.snipcart-payment__form-container--loading,
.snipcart-payment__loading {
  min-height: 160px
}

.snipcart-payment__form-container--loading * {
  visibility: hidden
}

.snipcart-payment-completed__title {
  margin-bottom: 24px
}

.snipcart-payment-completed__step-title {
  margin-bottom: 16px
}

.snipcart-featured-payment-methods {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 24px;
  flex-wrap: wrap;
  border-top: 1px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-default, #e3e6e8);
  margin-top: 40px;
  margin-bottom: 80px;
  position: relative
}

.snipcart-featured-payment-methods__title-icon {
  margin-right: 8px
}

.snipcart-featured-payment-methods__list {
  display: flex
}

.snipcart-featured-payment-methods__link {
  color: inherit;
  display: flex;
  align-items: center
}

.snipcart-featured-payment-methods__link:hover {
  color: inherit
}

.snipcart-featured-payment-methods__title {
  background-color: #f1f2f4;
  background-color: var(--bgColor-modal, #f1f2f4);
  position: absolute;
  top: -10px;
  padding-left: 16px;
  padding-right: 16px
}

.snipcart-featured-payment-methods__list-item {
  padding: 8px 16px;
  text-align: center
}

.snipcart-featured-payment-methods--no-background {
  border: none;
  margin-top: 0;
  margin-top: 32px
}

.snipcart-featured-payment-methods--no-background .snipcart-featured-payment-methods {
  border: none
}

.snipcart-featured-payment-methods--no-background .snipcart-featured-payment-methods__title {
  background-color: transparent
}

.snipcart-payment-methods-list__container {
  position: relative
}

.snipcart-payment-methods-list-item {
  margin-top: 10.66667px
}

.snipcart-payment-methods-list-item__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-default, #e3e6e8);
  padding: 16px;
  width: 100%;
  height: 64px;
  cursor: pointer
}

.snipcart-payment-methods-list-item__arrow {
  color: #0aa7f5;
  color: var(--color-icon, #0aa7f5)
}

.snipcart-payment-methods-list-item__label {
  text-align: left;
  display: flex;
  align-items: center
}

.snipcart-payment-methods-list-item__icon {
  height: 32px;
  width: auto;
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;

  &[title="Carte de crédit"] {
    background-image: url(../img/credit-card.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 105px;
    path {
      display: none;
    }
  }
}

.snipcart-shipping-completed__step-title {
  display: none
}

.snipcart-shipping-completed__header {
  margin-bottom: 24px
}

.snipcart-shipping-completed .snipcart-checkout-step__icon,
.snipcart-shipping-completed__title .snipcart__icon--left {
  color: #0aa7f5;
  color: var(--color-icon, #0aa7f5)
}

.snipcart-shipping-completed--titled .snipcart-shipping-completed__step-title {
  display: block;
  margin-bottom: 16px
}

.snipcart-shipping-completed--titled .snipcart-shipping-completed__step-icon {
  display: none
}

.snipcart-shipping-address__header--readonly {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.snipcart-shipping-address__content {
  margin-bottom: 10.66667px
}

.snipcart-shipping-address__subtitle {
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px
}

.snipcart-shipping-address__address-field {
  margin-bottom: 24px
}

.snipcart-shipping-rates-list-item {
  cursor: pointer;
  display: grid;
  grid-template-columns: 80% 20%;
  justify-content: space-between;
  align-items: center;
  margin: 3.2px 0;
  padding: 16px;
  background-color: #fff;
  background-color: var(--bgColor-input, #fff);
  border: 1px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-input, #e3e6e8)
}

.snipcart-shipping-rates-list-item:focus-within {
  border: 1px solid;
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-focus, #0aa7f5)
}

.snipcart-shipping-rates-list-item--right {
  white-space: nowrap;
  text-align: right
}

.snipcart-shipping-rates-list-item--highlight {
  border: 1px solid;
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-focus, #0aa7f5)
}

.snipcart-cart-summary {
  background-color: #f7f7f8;
  background-color: var(--bgColor-alt, #f7f7f8)
}

@media only screen and (max-width:600px) {
  .snipcart-cart-summary {
    height: 100vh;
    overflow-y: scroll
  }
}

.snipcart-cart-summary__content {
  background-color: #fff;
  background-color: var(--bgColor-default, #fff);
  border: 1px solid $color-primary;
  padding: 32px
}

.snipcart-cart-summary__small {
  background-color: hsla(0, 0%, 100%, .7);
  background-color: var(--bgColor-modalVeil, hsla(0, 0%, 100%, .7));
  position: absolute;
  left: 0;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  margin-top: 32px
}

.snipcart-cart-summary__small-transition-enter-active {
  animation: slideDown .3s
}

.snipcart-cart-summary__small-transition-leave-active {
  animation: slideDown .1s reverse
}

.snipcart-cart-summary__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 100%, .7);
  background-color: var(--bgColor-modalVeil, hsla(0, 0%, 100%, .7))
}

.snipcart-cart-summary__overlay-transition-enter-active {
  animation: fadeIn .3s
}

.snipcart-cart-summary__overlay-transition-leave-active {
  animation: fadeIn .1s reverse
}

.snipcart-cart-summary__items {
  list-style-type: none;
  margin: 0
}

.snipcart-cart-summary__separator {
  width: calc(100% + 64px);
  margin-left: -32px;
  margin-right: -32px;
  margin-top: 32px;
  margin-bottom: 32px;
  border-top: 1px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-default, #e3e6e8)
}

.snipcart-cart-summary__actions {
  margin-bottom: 24px
}

.snipcart-cart-summary--small {
  background-color: hsla(0, 0%, 100%, .7);
  background-color: var(--bgColor-modalVeil, hsla(0, 0%, 100%, .7));
  position: absolute;
  left: 0;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  margin-top: 16px
}

.snipcart-cart-summary--edit.snipcart-modal__container {
  border-left: 1px solid $color-primary;
}

@media(min-width:768px) {
  .snipcart-cart-summary--edit.snipcart-modal__container {
    width: 35%;
    max-width: 540px;
    margin-left: auto;
    right: 0
  }
}

.snipcart-cart-summary--edit.snipcart-modal__container--tablet-up {
  width: 35%;
  max-width: 540px;
  margin-left: auto;
  right: 0
}

.snipcart-cart-summary-item {
  margin: 0 12px 0 0;
  padding: 0;
  display: grid;
  grid-template-columns: 60% 30px auto;
  color: #2e3338;
  color: var(--color-default, #2e3338);
  padding: 3.2px 0
}

.snipcart-cart-summary-item__quantity {
  white-space: nowrap;
  text-align: center
}

.snipcart-cart-summary-item__price {
  text-align: right
}

.snipcart-cart-summary-item__custom-fields {
  list-style: none;
  margin: 0;
  padding: 0
}

.snipcart-cart-summary-item:not(:last-child) {
  margin-bottom: 8px
}

.snipcart-cart-summary-items-list {
  height: auto;
  width: auto;
  max-height: 256px
}

.snipcart-cart-summary-expanded-item {
  margin: 0;
  padding: 0;
  font-family: $font-secondary;
  font-size: 18px;
  padding: 3.6px 0;
  color: #2e3338;
  color: var(--color-default, #2e3338)
}

.snipcart-cart-summary-expanded-item__quantity {
  white-space: nowrap
}

@media(min-width:1024px) {
  .snipcart-cart-summary-expanded-item__price {
    text-align: right
  }
}

.snipcart-cart-summary-expanded-item__price--large {
  text-align: right
}

.snipcart-cart-summary-expanded-item__custom-fields {
  padding: 8px 0 8px 8px
}

@media(min-width:1024px) {
  .snipcart-cart-summary-expanded-item__custom-fields {
    padding: 8px 0
  }
}

.snipcart-cart-summary-expanded-item__custom-fields--large {
  padding: 8px 0
}

.snipcart-cart-summary-expanded-item--secondary {
  padding: 0 0 8px 8px
}

.snipcart-cart-summary-expanded-item--secondary:last-child {
  padding-bottom: 0
}

@media(min-width:1024px) {
  .snipcart-cart-summary-expanded-item {
    display: grid;
    grid-template-columns: 70% 50px auto
  }
}

.snipcart-cart-summary-expanded-item--large {
  display: grid;
  grid-template-columns: 70% 50px auto
}

.snipcart-tool-tip {
  position: absolute;
  z-index: 99;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  background-color: #fff;
  background-color: var(--bgColor-default, #fff);
  box-shadow: 0 8px 16px rgba(26, 77, 179, .15);
  box-shadow: var(--shadow-tooltip, 0 8px 16px rgba(26, 77, 179, .15));
  top: 0;
  left: 0
}

.snipcart-tool-tip__container {
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 1.5;
  position: relative
}

.snipcart-tool-tip--bordered .snipcart-tool-tip__container {
  border: 1px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-default, #e3e6e8)
}

.snipcart-checkout-step {
  padding-bottom: 16px;
  & > * {
    border: 1px solid $color-primary;
  }
}

.snipcart-checkout-step__cols {
  display: flex;
  flex-direction: column;
  justify-content: space-between
}

@media(min-width:1024px) {
  .snipcart-checkout-step__cols {
    flex-direction: unset
  }
}

.snipcart-checkout-step__cols--large {
  flex-direction: unset
}

.snipcart-checkout-step__col {
  display: flex;
  align-items: center;
  width: 100%
}

.snipcart-checkout-step__col:not(:last-child) {
  margin-bottom: 16px
}

@media(min-width:1024px) {
  .snipcart-checkout-step__col {
    width: 40%
  }
  .snipcart-checkout-step__col:not(:last-child) {
    margin-bottom: 0
  }
}

.snipcart-checkout-step__col--large {
  width: 40%
}

.snipcart-checkout-step__col--large:not(:last-child) {
  margin-bottom: 0
}

.snipcart-checkout-step__icon {
  width: 20px;
  margin-right: 5px;
  margin-top: 2px
}

.snipcart-customer-details {
  margin-bottom: 32px
}

.snipcart-customer-details__header {
  margin-bottom: 12px
}

.snipcart-customer-details__infos {
  padding-bottom: 24px;
  margin-bottom: 28px;
  border-bottom: 1px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-default, #e3e6e8);
  font-size: 20px;
  line-height: 1.2
}

.snipcart-customer-details-group {
  margin-bottom: 20px
}

.snipcart-customer-details-group:last-child {
  margin-bottom: 0
}

.snipcart-customer-details-group {
  display: grid;
  grid-template-columns: 25px 1fr auto;
  grid-template-areas: "icon header action" ". content .";
  align-items: center
}

.snipcart-customer-details-group__icon {
  grid-area: icon;
  color: #0aa7f5;
  color: var(--color-icon, #0aa7f5)
}

.snipcart-customer-details-group__title {
  grid-area: header
}

.snipcart-customer-details-group__action {
  grid-area: action;
  font-weight: 600
}

.snipcart-customer-details-group__content {
  grid-area: content;
  line-height: 1.2
}

.snipcart-customer-details-group__content.is-header {
  grid-area: header
}

.snipcart-payment-info__header {
  margin-top: 32px;
  margin-bottom: 12px
}

.snipcart-payment-info__details-ctn {
  border: 1px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-default, #e3e6e8);
  border-radius: 8px
}

.snipcart-payment-info .snipcart-payment-details {
  border-bottom: 1px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-default, #e3e6e8)
}

.snipcart-payment-info .snipcart-payment-details:last-child {
  border-bottom: none
}

.snipcart-order {
  width: 100%
}

.snipcart-order__header {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 24px
}

.snipcart-order__header-item:last-child {
  text-align: right
}

.snipcart-order__header-title {
  margin-bottom: 8px;
  font-size: 16px
}

.snipcart-order__status {
  font-weight: 600
}

.snipcart-order__status.is-active {
  color: #29c768;
  color: var(--color-success, #29c768)
}

.snipcart-order__product-ctn {
  border: 1px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-default, #e3e6e8);
  border-radius: 8px
}

.snipcart-order .snipcart-card-product {
  border-bottom: 1px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-default, #e3e6e8)
}

.snipcart-order .snipcart-card-product:last-child {
  border-bottom: none
}

.snipcart-subscription {
  width: 100%
}

.snipcart-subscription__header {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 24px
}

.snipcart-subscription__header-item:last-child {
  text-align: right
}

.snipcart-subscription__header-title {
  margin-bottom: 8px;
  font-size: 16px
}

.snipcart-subscription__product-ctn {
  border: 1px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-default, #e3e6e8);
  border-radius: 8px
}

.snipcart-subscription .snipcart-card-product {
  border-bottom: 1px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-default, #e3e6e8)
}

.snipcart-subscription .snipcart-card-product:last-child {
  border-bottom: none
}

.snipcart-dashboard__notifications-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px
}

@media(min-width:620px) {
  .snipcart-dashboard__notifications-container {
    width: 600px;
    padding: 0
  }
}

@media(min-width:960px) {
  .snipcart-dashboard__notifications-container {
    margin: 0 auto;
    padding: 0 32px;
    width: 100%
  }
}

@media(min-width:1100px) {
  .snipcart-dashboard__notifications-container {
    width: 1100px;
    padding: 0
  }
}

.snipcart-dashboard__notifications-list {
  width: 100%;
  margin-bottom: 32px
}

@media(min-width:1024px) {
  .snipcart-discount-box {
    width: 100%;
    margin: 0
  }
}

.snipcart-discount-box--large {
  width: 100%;
  margin: 0
}

.snipcart-discount-box__success-icon {
  margin-left: 16px
}

.snipcart-discount-box__button {
  cursor: pointer;
  box-sizing: border-box;
  padding: 16px 0;
  text-align: center;
  width: 100%;
  border: 1px solid;
  color: #2e3338;
  color: var(--color-input, #2e3338);
  border-color: #e3e6e8;
  border-color: var(--borderColor-input, #e3e6e8);
  background-color: #fff;
  background-color: var(--bgColor-input, #fff)
}

.snipcart-discount-box__button:hover {
  color: #2e3338;
  color: var(--color-input-hover, #2e3338);
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-hover, #0aa7f5);
  background-color: #fff;
  background-color: var(--bgColor-input-hover, #fff);
  box-shadow: none;
  box-shadow: var(--shadow-input-hover, none)
}

.snipcart-discount-box__button:focus {
  color: #2e3338;
  color: var(--color-input-focus, #2e3338);
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-focus, #0aa7f5);
  background-color: #fff;
  background-color: var(--bgColor-input-focus, #fff);
  box-shadow: 0 5px 10px -3px rgba(10, 167, 245, .3);
  box-shadow: var(--shadow-input-focus, 0 5px 10px -3px rgba(10, 167, 245, .3));
  outline: none
}

.snipcart-discount-box__form {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  box-sizing: border-box
}

.snipcart-discount-box__form--success {
  justify-content: center;
  height: 52px;
  border: 1px solid;
  color: #29c768;
  color: var(--color-success, #29c768);
  border-color: var(--borderColor-success, );
  background-color: #e2f5e0;
  background-color: var(--bgColor-success, #e2f5e0)
}

.snipcart-discount-box .snipcart-input {
  padding-right: 5ch
}

.snipcart-discount-box__form-container {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.snipcart-discount-box__submit {
  position: absolute;
  right: 16px
}

.snipcart-discount-box__cancel {
  color: #2e3338;
  color: var(--color-default, #2e3338);
  margin-left: 8px
}

@media(min-width:768px) and (max-width:1024px) {
  .snipcart-form__address-autocomplete .snipcart-typeahead__suggestions {
    position: absolute;
    background-color: #fff;
    background-color: var(--bgColor-default, #fff);
    height: unset
  }
  .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus__input {
    background-color: #fff;
    background-color: var(--bgColor-default, #fff)
  }
  .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus__suggestions {
    display: flex;
    flex-direction: column;
    padding-bottom: 50vh
  }
  .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus__suggestion--highlight {
    display: block
  }
  .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus__suggestion--loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
  }
  .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus--active .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus .snipcart-typeahead___content {
    display: flex;
    flex-direction: column;
    height: 100%
  }
  .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus--active .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus .snipcart-typeahead__suggestions {
    position: relative;
    max-height: 100vh;
    height: 100vh;
    margin: 0
  }
}

.snipcart__media-query--tablet .snipcart-form__address-autocomplete .snipcart-typeahead__suggestions {
  position: absolute;
  background-color: #fff;
  background-color: var(--bgColor-default, #fff);
  height: unset
}

.snipcart__media-query--tablet .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus__input {
  background-color: #fff;
  background-color: var(--bgColor-default, #fff)
}

.snipcart__media-query--tablet .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus__suggestions {
  display: flex;
  flex-direction: column;
  padding-bottom: 50vh
}

.snipcart__media-query--tablet .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus__suggestion--highlight {
  display: block
}

.snipcart__media-query--tablet .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus__suggestion--loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column
}

.snipcart__media-query--tablet .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus--active .snipcart__media-query--tablet .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus .snipcart-typeahead___content {
  display: flex;
  flex-direction: column;
  height: 100%
}

.snipcart__media-query--tablet .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus--active .snipcart__media-query--tablet .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus .snipcart-typeahead__suggestions {
  position: relative;
  max-height: 100vh;
  height: 100vh;
  margin: 0
}

@media(max-width:768px) {
  .snipcart-form__address-autocomplete .snipcart-typeahead__suggestions {
    position: absolute;
    background-color: #fff;
    background-color: var(--bgColor-default, #fff);
    height: unset
  }
  .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus__input {
    background-color: #fff;
    background-color: var(--bgColor-default, #fff)
  }
  .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus__suggestions {
    display: flex;
    flex-direction: column;
    padding-bottom: 50vh
  }
  .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus__suggestion--highlight {
    display: block
  }
  .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus__suggestion--loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
  }
  .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus--active .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus .snipcart-typeahead___content {
    display: flex;
    flex-direction: column;
    height: 100%
  }
  .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus--active .snipcart-form__address-autocomplete .snipcart-typeahead__select--focus .snipcart-typeahead__suggestions {
    position: relative;
    max-height: 100vh;
    height: 100vh;
    margin: 0
  }
}

.snipcart-form__address-autocomplete--mobile .snipcart-typeahead__suggestions {
  position: absolute;
  background-color: #fff;
  background-color: var(--bgColor-default, #fff);
  height: unset
}

.snipcart-form__address-autocomplete--mobile .snipcart-typeahead__select--focus__input {
  background-color: #fff;
  background-color: var(--bgColor-default, #fff)
}

.snipcart-form__address-autocomplete--mobile .snipcart-typeahead__select--focus__suggestions {
  display: flex;
  flex-direction: column;
  padding-bottom: 50vh
}

.snipcart-form__address-autocomplete--mobile .snipcart-typeahead__select--focus__suggestion--highlight {
  display: block
}

.snipcart-form__address-autocomplete--mobile .snipcart-typeahead__select--focus__suggestion--loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column
}

.snipcart-form__address-autocomplete--mobile .snipcart-typeahead__select--focus--active .snipcart-form__address-autocomplete--mobile .snipcart-typeahead__select--focus .snipcart-typeahead___content {
  display: flex;
  flex-direction: column;
  height: 100%
}

.snipcart-form__address-autocomplete--mobile .snipcart-typeahead__select--focus--active .snipcart-form__address-autocomplete--mobile .snipcart-typeahead__select--focus .snipcart-typeahead__suggestions {
  position: relative;
  max-height: 100vh;
  height: 100vh;
  margin: 0
}

.snipcart-form__address-autocomplete .snipcart-textbox {
  background-image: none
}

.snipcart-form__address-autocomplete>.snipcart-textbox {
  height: auto;
  min-height: 52px
}

.snipcart-form__address-autocomplete>.snipcart-typeahead__select {
  height: 0;
  opacity: 0
}

.snipcart-form__address-autocomplete--active>.snipcart-typeahead__select {
  height: unset;
  opacity: 1
}

.snipcart-form__address-autocomplete .snipcart-typeahead__suggestion--powered-by-google {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  justify-content: flex-end
}

.snipcart-checkbox {
  position: absolute;
  opacity: 0;
  transition: box-shadow .2s ease-in-out
}

.snipcart-checkbox+label {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0
}

.snipcart-checkbox+label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid;
  color: #2e3338;
  color: var(--color-input, #2e3338);
  border-color: #e3e6e8;
  border-color: var(--borderColor-input, #e3e6e8);
  background-color: #fff;
  background-color: var(--bgColor-input, #fff);
  transition: .2s ease-out;
  transition-property: color, border-color, background-color
}

.snipcart-checkbox:hover+label:before {
  color: #2e3338;
  color: var(--color-input-hover, #2e3338);
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-hover, #0aa7f5);
  background-color: #fff;
  background-color: var(--bgColor-input-hover, #fff);
  box-shadow: none;
  box-shadow: var(--shadow-input-hover, none)
}

.snipcart-checkbox:focus+label:before {
  color: #2e3338;
  color: var(--color-input-focus, #2e3338);
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-focus, #0aa7f5);
  background-color: #fff;
  background-color: var(--bgColor-input-focus, #fff);
  box-shadow: 0 5px 10px -3px rgba(10, 167, 245, .3);
  box-shadow: var(--shadow-input-focus, 0 5px 10px -3px rgba(10, 167, 245, .3))
}

.snipcart-checkbox:checked+label:before {
  color: #fff;
  color: var(--color-input-checked, #fff);
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-checked, #0aa7f5);
  background-color: #0aa7f5;
  background-color: var(--bgColor-input-checked, #0aa7f5);
  box-shadow: none;
  box-shadow: var(--shadow-input-checked, none)
}

.snipcart-checkbox:checked:focus+label:before {
  box-shadow: 0 5px 10px -3px rgba(10, 167, 245, .3);
  box-shadow: var(--shadow-input-focus, 0 5px 10px -3px rgba(10, 167, 245, .3))
}

.snipcart-checkbox:checked+label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  color: #fff;
  color: var(--color-input-checked, #fff);
  background-color: currentColor;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 currentColor, 4px 0 0 currentColor, 4px -2px 0 currentColor, 4px -4px 0 currentColor, 4px -6px 0 currentColor, 4px -8px 0 currentColor;
  transform: rotate(45deg)
}

.snipcart-error-message {
  padding-top: 8px
}

.snipcart-error-message__action {
  color: #1a4db3;
  color: var(--color-link, #1a4db3)
}

.snipcart-error-message--global-form {
  padding-top: 0;
  padding-bottom: 8px
}

.snipcart-error-message .snipcart-error-message--input {
  padding-top: 0
}

.snipcart-error-message--input {
  color: #9e2215;
  color: var(--color-input-error, #9e2215)
}

.snipcart-form {
  background-color: #fff;
  background-color: var(--bgColor-default, #fff);
}

.snipcart-form__field {
  padding: 8px 0
}

@media(min-width:1024px) {
  .snipcart-form__field {
    position: relative
  }
}

.snipcart-form__field--large {
  position: relative
}

.snipcart-form__field-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 3.2px
}

.snipcart-form__hidden-autofill:-webkit-autofill {
  background-color: #d9f0fc;
  background-color: var(--bgColor-input-autofill, #d9f0fc)
}

.snipcart-form__select-wrapper {
  position: relative;
  display: flex;
  align-items: center
}

.snipcart-form__select-wrapper .snipcart__icon {
  pointer-events: none;
  position: absolute;
  right: 8px;
  width: 20px;
  color: #8f99a3;
  color: var(--color-inputIcon, #8f99a3);
  transition: .2s ease-out;
  transition-property: transform, color
}

.snipcart-form__select-wrapper:hover .snipcart__icon {
  color: #2e3338;
  color: var(--color-inputIcon-hover, #2e3338)
}

.snipcart-form__select-wrapper:focus-within .snipcart__icon {
  transform: scaleY(-1);
  color: #0aa7f5;
  color: var(--color-inputIcon-focus, #0aa7f5)
}

.snipcart-form__select {
  display: flex;
  align-items: center;
  line-height: normal;
  height: 52px;
  border: 1px solid;
  color: #2e3338;
  color: var(--color-input, #2e3338);
  border-color: #e3e6e8;
  border-color: var(--borderColor-input, #e3e6e8);
  transition: .2s ease-out;
  transition-property: color, border-color, background-color;
  width: 100%;
  padding: 16px;
  padding-right: 50px;
  background-color: #fff;
  background-color: var(--bgColor-input, #fff);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: unset
}

.snipcart-form__select:hover {
  color: #2e3338;
  color: var(--color-input-hover, #2e3338);
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-hover, #0aa7f5);
  background-color: #fff;
  background-color: var(--bgColor-input-hover, #fff)
}

.snipcart-form__select:focus-within {
  color: #2e3338;
  color: var(--color-input-focus, #2e3338);
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-focus, #0aa7f5);
  background-color: #fff;
  background-color: var(--bgColor-input-focus, #fff);
  box-shadow: 0 5px 10px -3px rgba(10, 167, 245, .3);
  box-shadow: var(--shadow-input-focus, 0 5px 10px -3px rgba(10, 167, 245, .3))
}

.snipcart-form__select:-webkit-autofill {
  background-color: #d9f0fc;
  background-color: var(--bgColor-input-autofill, #d9f0fc)
}

.snipcart-form__select:focus {
  outline: none;
  border: 1px solid;
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-focus, #0aa7f5)
}

.snipcart-form__set {
  border: none;
  padding: 0
}

.snipcart-form__footer {
  margin-top: 8px
}

.snipcart-form__row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between
}

.snipcart-form__cell--large {
  width: 100%;
  margin-right: 32px
}

.snipcart-form__cell--large:last-child {
  margin-right: 0
}

.snipcart-form__cell--tidy {
  width: 30%;
  margin-right: 32px
}

.snipcart-form__cell--tidy:last-child {
  margin-right: 0
}

.snipcart-form__cell--input-aligned {
  margin-bottom: 15px;
  margin-top: auto
}

.snipcart-form__cell--1st-half {
  width: 50%;
  margin-right: 10.66667px
}

.snipcart-form__cell--2nd-half {
  width: 50%
}

.snipcart-form__separator {
  width: 100%;
  border-top: 1px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-default, #e3e6e8);
  margin-top: 16px;
  margin-bottom: 16px
}

.snipcart-form__label {
  font-weight: 400;
  font-size: 18px;
  color: #2e3338;
  color: var(--color-inputLabel, #2e3338);
  margin-bottom: 8px;
  display: inline-block;
  white-space: nowrap
}

.snipcart-form__label--radio {
  margin-bottom: 0;
  white-space: nowrap
}

.snipcart-form__label--checkbox {
  margin-bottom: 0
}

@media(min-width:1024px) {
  .snipcart-form__label--mobile-only {
    display: none
  }
}

.snipcart-form__label--mobile-only--large {
  display: none
}

.snipcart-input {
  line-height: normal;
  height: 52px;
  border: 1px solid;
  color: #2e3338;
  color: var(--color-input, #2e3338);
  border-color: #e3e6e8;
  border-color: var(--borderColor-input, #e3e6e8);
  background-color: #fff;
  background-color: var(--bgColor-input, #fff);
  transition: .2s ease-out;
  transition-property: color, border-color, background-color;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center
}

.snipcart-input:hover {
  color: #2e3338;
  color: var(--color-input-hover, #2e3338);
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-hover, #0aa7f5);
  background-color: #fff;
  background-color: var(--bgColor-input-hover, #fff)
}

.snipcart-input:focus-within {
  color: #2e3338;
  color: var(--color-input-focus, #2e3338);
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-focus, #0aa7f5);
  background-color: #fff;
  background-color: var(--bgColor-input-focus, #fff);
}

.snipcart-input--icon .snipcart-input__input {
  padding-left: 44px
}

.snipcart-input__input {
  flex: 1;
  outline: none;
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px
}

.snipcart-input__input:-webkit-autofill {
  background-color: #d9f0fc;
  background-color: var(--bgColor-input-autofill, #d9f0fc)
}

.snipcart-input--no-style {
  border: none;
  background-color: transparent
}

.snipcart-input--no-style:focus-within {
  border: none;
  box-shadow: none
}

.snipcart-input--no-style .snipcart-input__input {
  padding: 0
}

.snipcart-input__left-icon {
  position: absolute;
  left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8f99a3;
  color: var(--color-inputIcon, #8f99a3);
  transition: color .2s ease-out;
  z-index: 1
}

.snipcart-input:hover .snipcart-input__left-icon {
  color: #2e3338;
  color: var(--color-inputIcon-hover, #2e3338)
}

.snipcart-input--focus .snipcart-input__left-icon {
  color: #0aa7f5;
  color: var(--color-inputIcon-focus, #0aa7f5)
}

.snipcart-input--invalid {
  color: #9e2215;
  color: var(--color-input-error, #9e2215);
  border-color: #e85a4a;
  border-color: var(--borderColor-input-error, #e85a4a);
  background-color: #fff;
  background-color: var(--bgColor-input-error, #fff)
}

.snipcart-input--invalid .snipcart-input__left-icon {
  color: #e85a4a;
  color: var(--color-inputIcon-error, #e85a4a)
}

.snipcart-input__visibility-toggle {
  display: none
}

.snipcart-input--togleable .snipcart-input__input {
  padding-right: 64px
}

.snipcart-input__input:-webkit-autofill,
.snipcart-input__input:autofill {
  background-color: #d9f0fc;
  background-color: var(--bgColor-input-autofill, #d9f0fc)
}

.snipcart-input__input::-webkit-input-placeholder {
  opacity: 1;
  color: #8f99a3;
  color: var(--color-inputPlaceholder, #8f99a3)
}

.snipcart-input__input:-ms-input-placeholder {
  opacity: 1;
  color: #8f99a3;
  color: var(--color-inputPlaceholder, #8f99a3)
}

.snipcart-input__input::-ms-input-placeholder {
  opacity: 1;
  color: #8f99a3;
  color: var(--color-inputPlaceholder, #8f99a3)
}

.snipcart-input__input::placeholder {
  opacity: 1;
  color: #8f99a3;
  color: var(--color-inputPlaceholder, #8f99a3)
}

.snipcart-input__show-label {
  cursor: pointer;
  position: absolute;
  right: 12px;
  margin-bottom: 0;
  color: #8f99a3;
  color: var(--color-alt, #8f99a3);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 8px
}

.snipcart-form-radio {
  position: absolute;
  opacity: 0
}

.snipcart-form-radio__label {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 0;
  margin: 0;
  white-space: normal
}

.snipcart-form-radio+label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  border-radius: 99px;
  width: 18px;
  min-width: 18px;
  height: 18px;
  border: 1px solid;
  color: #2e3338;
  color: var(--color-input, #2e3338);
  border-color: #e3e6e8;
  border-color: var(--borderColor-input, #e3e6e8);
  background-color: #fff;
  background-color: var(--bgColor-input, #fff)
}

.snipcart-form-radio:hover+label:before {
  color: #2e3338;
  color: var(--color-input-hover, #2e3338);
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-hover, #0aa7f5);
  background-color: #fff;
  background-color: var(--bgColor-input-hover, #fff);
  box-shadow: none;
  box-shadow: var(--shadow-input-hover, none)
}

.snipcart-form-radio:focus+label:before {
  color: #2e3338;
  color: var(--color-input-focus, #2e3338);
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-focus, #0aa7f5);
  background-color: #fff;
  background-color: var(--bgColor-input-focus, #fff);
  box-shadow: 0 5px 10px -3px rgba(10, 167, 245, .3);
  box-shadow: var(--shadow-input-focus, 0 5px 10px -3px rgba(10, 167, 245, .3))
}

.snipcart-form-radio:checked+label:before {
  color: #fff;
  color: var(--color-input-checked, #fff);
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-checked, #0aa7f5);
  background-color: #0aa7f5;
  background-color: var(--bgColor-input-checked, #0aa7f5);
  box-shadow: none;
  box-shadow: var(--shadow-input-checked, none)
}

.snipcart-form-radio:checked+label:after {
  content: url(../img/checkmark.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  z-index: 99;
  position: absolute;
  left: -3px;
  top: -3px;
  width: 24px;
  height: 24px;
  border: none
}

.snipcart-textarea {
  padding: 8px
}

.snipcart-typeahead__input {
  cursor: pointer
}

.snipcart-typeahead__label {
  display: block;
  margin: 0;
  padding: 3.2px 0
}

.snipcart-typeahead__dropdown {
  background-color: #fff;
  background-color: var(--bgColor-input, #fff)
}

.snipcart-typeahead__dropdown--no-focus {
  cursor: pointer
}

.snipcart-typeahead--active {
  top: 0;
  left: 0;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: transparent;
  z-index: 100;
  display: flex;
  flex-direction: column;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain
}

.snipcart-typeahead--active .snipcart-typeahead__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-color: var(--bgColor-default, #fff)
}

.snipcart-typeahead--invalid {
  border-color: #e85a4a;
  border-color: var(--borderColor-input-error, #e85a4a)
}

.snipcart-typeahead--autofilled,
.snipcart-typeahead--autofilled .snipcart-form__select {
  background-color: #d9f0fc;
  background-color: var(--bgColor-input-autofill, #d9f0fc)
}

.snipcart-textbox {
  display: flex;
  align-items: center;
  justify-items: flex-start
}

.snipcart-textbox--hidden {
  border: none;
  background-color: transparent;
  width: 100%
}

.snipcart-textbox--hidden:focus {
  outline: none
}

.snipcart-textbox--with-icon {
  padding-left: 8px
}

.snipcart-textbox--focus {
  outline: none;
  border: 1px solid;
  border-color: #0aa7f5;
  border-color: var(--borderColor-input-focus, #0aa7f5);
  box-shadow: 0 5px 10px -3px rgba(10, 167, 245, .3);
  box-shadow: var(--shadow-input-focus, 0 5px 10px -3px rgba(10, 167, 245, .3))
}

.snipcart-textbox--invalid {
  border: 1px solid;
  border-color: #e85a4a;
  border-color: var(--borderColor-input-error, #e85a4a)
}

.snipcart-textbox__icon {
  margin-right: 10.66667px;
  color: #8f99a3;
  color: var(--color-inputIcon, #8f99a3)
}

.snipcart-modal__container {
  width: calc(100% + 1px);
  overflow-x: hidden;
  padding-right: 1px
}

.snipcart-layout {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column
}

.snipcart-layout__header {
  height: 100px;
  min-height: 100px;
  max-width: 1440px
}

@media(min-width:768px) {
  .snipcart-layout__header {
    width: 80%;
    margin: 0 auto
  }
}

.snipcart-layout__header--tablet-up {
  width: 80%;
  margin: 0 auto
}

.snipcart-layout__content {
  height: 100%;
  max-width: 1440px
}

@media(min-width:768px) {
  .snipcart-layout__content {
    width: 80%;
    margin: 0 auto
  }
}

.snipcart-layout__content--tablet-up {
  width: 80%;
  margin: 0 auto
}

.snipcart-layout__content--side {
  min-height: 100%;
  width: 100%
}

.snipcart-layout__footer {
  background-color: #f1f2f4;
  background-color: var(--bgColor-modal, #f1f2f4)
}

.snipcart-layout__cols {
  display: flex;
  align-items: flex-start;
  justify-content: space-around
}

.snipcart-layout__col {
  width: 100%;
  margin: 0 auto
}

@media(min-width:1024px) {
  .snipcart-layout__col {
    width: 50%;
    margin: 0 16px
  }
}

.snipcart-layout__col--large {
  width: 50%;
  margin: 0 16px;
  width: 100%
}

@media(min-width:1024px) {
  .snipcart-layout__col--large {
    width: 70%
  }
}

.snipcart-layout__col--large--large {
  width: 70%
}

.snipcart-layout--large {
  left: 0
}

.snipcart-layout--no-scroll {
  overflow: hidden
}

@media(min-width:1024px) {
  .snipcart-layout--no-scroll {
    overflow: auto
  }
}

.snipcart-layout--no-scroll--large {
  overflow: auto
}

.snipcart-layout--not-visible {
  display: none
}

.snipcart-layout-sidebar {
  padding-left: 16px;
  padding-right: 16px
}

@media(min-width:620px) {
  .snipcart-layout-sidebar {
    padding-left: 32px;
    padding-right: 32px
  }
}

.snipcart-layout-sidebar__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1100px;
  max-width: 100%;
  margin: 0 auto
}

@media(min-width:960px) {
  .snipcart-layout-sidebar__container {
    display: grid;
    align-items: start;
    gap: 32px;
    grid-template-columns: 40fr 60fr;
    grid-template-areas: "sidebar main"
  }
}

@media(min-width:1100px) {
  .snipcart-layout-sidebar__container {
    gap: 40px
  }
}

.snipcart-layout-sidebar__aside {
  width: 100%;
  grid-area: sidebar
}

@media(max-width:960px) {
  .snipcart-layout-sidebar__aside {
    max-width: 600px
  }
}

.snipcart-layout-sidebar__main {
  width: 100%;
  grid-area: main
}

@media(max-width:960px) {
  .snipcart-layout-sidebar__main {
    max-width: 600px
  }
}

.snipcart-order__payment-instructions {
  padding-top: 16px
}

.snipcart-addresss-formatted {
  line-height: 1.2
}

.snipcart-addresss-formatted span {
  display: inline
}

.snipcart-card-icon {
  position: relative;
  margin-right: 8px;
  border: 1.25px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-default, #e3e6e8);
  border-radius: 3px;
  width: 32px;
  height: 22.85714px
}

.snipcart-card-icon svg {
  width: 100%;
  max-width: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.snipcart__loading__screen .snipcart__icon {
  width: 42px;
  height: 42px;
  animation: spin .7s linear 0s infinite
}

.snipcart__loading__screen__content {
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity .2s ease-out
}

.snipcart__loading__screen__content.is-visible {
  opacity: 1
}

.snipcart__loading__screen__content__message {
  padding: 32px
}

.snipcart-loading-overlay {
  position: relative
}

.snipcart-loading-overlay__pending {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 150
}

.snipcart-loading-overlay__pending .snipcart__icon {
  animation: spin .7s linear 0s infinite
}

.snipcart-loading-overlay__pending-message {
  padding: 32px
}

.snipcart-loading-overlay__content {
  z-index: 149
}

.snipcart-loading-overlay__content--loading {
  opacity: .5
}

.snipcart-loading-overlay__content--loading-opaque {
  opacity: 0
}

.snipcart-loading-component .snipcart__icon {
  width: 42px;
  height: 42px;
  animation: spin .7s linear 0s infinite
}

.snipcart-loading-component__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.snipcart-loading-component__content--small {
  height: auto
}

.snipcart-loading-component__content__message {
  padding: 32px
}

.snipcart-flash-message {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px
}

.snipcart-flash-message--error {
  background-color: #fceae8;
  background-color: var(--bgColor-error, #fceae8)
}

.snipcart-flash-message--success {
  background-color: #e2f5e0;
  background-color: var(--bgColor-success, #e2f5e0)
}

.snipcart-flash-message--info {
  background-color: #e7f6fe;
  background-color: var(--bgColor-info, #e7f6fe)
}

.snipcart-flash-message:not(:first-of-type) {
  margin-top: 8px
}

.snipcart-flash-message__icon {
  display: none;
  min-width: 32px;
  height: auto;
  margin-bottom: auto;
  margin-right: 16px
}

@media(min-width:768px) {
  .snipcart-flash-message__icon {
    display: block
  }
}

.snipcart-flash-message__icon--tablet-up {
  display: block
}

.snipcart-flash-message__action {
  cursor: pointer;
  color: #1a4db3;
  color: var(--color-link, #1a4db3)
}

.snipcart-flash-message__action-container {
  display: flex;
  margin-top: 16px
}

.snipcart-flash-message__action-container>* {
  margin-left: 16px
}

.snipcart-flash-message__action-container>:first-child {
  margin-left: 0
}

.snipcart-flash-message__content a {
  display: inline
}

.snipcart-flash-message__description:not(:first-child) {
  margin-top: 8px
}

.snipcart-tabs__toggle {
  display: flex;
  width: 100%;
  border: 1.5px solid;
  border-color: #e3e6e8;
  border-color: var(--borderColor-default, #e3e6e8);
  border-radius: 4px;
  position: relative;
  margin-bottom: 16px
}

.snipcart-tabs__slider {
  position: absolute;
  height: 100%;
  background-color: #fff;
  background-color: var(--bgColor-default, #fff);
  z-index: 98;
  width: 50%;
  width: var(--width);
  transform: translateX(0);
  transform: translateX(var(--position));
  transition: .2s ease-out;
  transition-property: transform, border-radius
}

.snipcart-tabs__slider.at-start {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px
}

.snipcart-tabs__slider.at-end {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px
}

.snipcart-tabs__tab-button {
  cursor: pointer;
  flex: 1 1 0;
  text-align: center;
  color: #8f99a3;
  color: var(--color-alt, #8f99a3);
  font-size: 20px;
  padding: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
  z-index: 99
}

.snipcart-tabs__tab-button.is-active {
  color: #2e3338;
  color: var(--color-default, #2e3338);
  font-weight: 600;
  cursor: auto
}

.snipcart-paginated-list .snipcart-button-primary,
.snipcart-paginated-list__item {
  margin-bottom: 16px
}

.snipcart-paginated-list__notice {
  margin-top: 80px;
  width: 100%;
  text-align: center
}

.snipcart-spinner__spinning {
  animation: spin 1s linear infinite;
  transform-origin: center
}

.snipcart-cart-summary-side {
  height: 100%;
  overflow: hidden
}

.snipcart-cart-summary-side .snipcart-cart__secondary-header {
  padding: 0 32px
}

.snipcart-cart-summary-side .snipcart-cart__content {
  overflow: hidden;
  flex-grow: 1
}

.snipcart-cart-summary-side .snipcart-cart__content .snipcart-item-list .snipcart-item-line {
  padding: 0;
  margin: 0
}

.snipcart-cart-summary-side .snipcart-cart__content .snipcart-item-list .snipcart-item-line .snipcart-item-line__container {
  padding: 32px
}

.snipcart-cart-summary-side .snipcart-cart__content .snipcart-item-list .snipcart-item-line:last-child .snipcart-item-line__container {
  margin: 0
}

.snipcart-cart-summary-side .snipcart-cart__content .snipcart-item-list .snipcart-discount-box {
  width: 100%;
  margin-bottom: 0
}

.snipcart-cart-summary-side .snipcart-cart__footer {
  padding: 32px 32px 0
}

.snipcart-cart-summary-side .snipcart-cart__footer .snipcart__actions--link {
  margin-top: 8px;
  text-align: center
}

@keyframes slideIn {
  0% {
    transform: translateX(110%)
  }
  to {
    transform: translateX(0)
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(1turn)
  }
}

@keyframes slideDown {
  0% {
    height: 0
  }
  to {
    height: 100vh
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@keyframes popUp {
  0% {
    opacity: 0;
    transform: scale(.35)
  }
  to {
    opacity: 1;
    transform: scale(1)
  }
}

.snipcart-transition--slide-in-enter-active {
  animation: slideIn .5s
}

.snipcart-transition--slide-in-leave-active {
  animation: slideIn .3s reverse
}

.snipcart-transition--popup-enter-active {
  animation: popUp .2s
}

.snipcart-transition--popup-leave-active {
  animation: popUp .2s reverse
}

.snipcart-cart--opened {
  overflow: hidden
}

.snipcart-sidecart--opened::-webkit-scrollbar {
  width: 0
}