/**
 * Global
 */

// Fonts
$font-primary: 'Libre Baskerville', serif;
$font-secondary: 'Alegreya Sans', sans-serif;

// Base
$base-font-size: 18px;
$base-line-height: 1.7;

// Heading
$heading-font-family: $font-secondary;
$heading-font-weight: 700;
$heading-line-height: 1.2;
$heading-magin: 0 0 25px;

// Default
$default-margin: 0 0 25px;
$default-wrap: 1280px;
$default-gutter: 20px;

$scroll-transition: transform .5s $ease-out-quart, opacity .5s $ease-out-quad;
$scroll-transform-base: 30px;