/**
 * Body
 */

body {
  font-family: $font-primary;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: 400;
  color: $body-color;
  background-color: $body-background;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  &.open-menu {
    overflow: hidden;
  }
}
