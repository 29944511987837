.author {

  &__main {
    @include wrap(1000px);
  }

  &__columns {
    @include mq($from: tablet) {
      display: flex;
    }
  }

  &__content {
    @include mq($from: tablet) {
      flex: 1;
      margin-left: 100px;
    }
  }

  &__sidebar {
    margin-top: 50px;
    @include mq($from: tablet) {
      margin-top: 0;
      order: -1;
      width: 320px;
    }
  }

  &__picture {
    position: relative;
    @include size(200px);
    @include img-object-fit;
    border-radius: 50%;
    overflow: hidden;

    &:after {
      content: "";
      @include overlay;
      border-radius: 50%;
      z-index: 1;

      .author--red & {
        background-color: rgba($color-secondary,.35);
      }

      .author--yellow & {
        background-color: rgba($color-tertiary,.35);
      }

      .author--green & {
        background-color: rgba($color-primary,.35);
      }
    }

    img {
      border-radius: 50%;
    }
  }

  &__social,
  &__events,
  &__resources {
    margin-top: 40px;
    ul {
      @include list-reset;
      li {
        margin: 0;
      }
    }
  }

  &__events,
  &__resources {
    ul {
      li {
        &:not(:first-child) {
          margin-top: 25px;
        }
      }
    }
  }

  &__title {
    font-family: $font-primary;
    font-weight: 400;
    font-size: 50px;
  }

}