/**
 * Media
 */

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

img[width],
img[height] {
  max-width: none;
}

object,
iframe,
embed,
canvas,
video,
audio {
  max-width: 100%;
}
