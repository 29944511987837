/**
 * Liste non stylisée
 * Usage:
   @include list-unstyled;
 */
@mixin list-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}
