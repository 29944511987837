.card {
  @include aspect-ratio(1,1);
  background-color: $color-gray;

  &__content {
    @include overlay;
    padding: 40px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}