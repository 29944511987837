/**
 * Text selection
 */

::selection {
  background: $color-primary;
  color: white;
}

::-moz-selection {
  background: $color-primary;
  color: white;
}