.card-event {

  position: relative;
  padding: 20px 0;
  display: flex;
  flex-flow: row wrap;

  @include mq($from: tablet) {
    padding: 40px 0;
    border-top: 1px solid $color-primary;

    .events__list-item:last-child & {
      border-bottom: 1px solid $color-primary;
    }
  }

  &__image {
    @include mq($until: tablet) {
      width: 100%;
      margin-bottom: 30px;
      display: none;
    }
  }

  &__picture {
    position: relative;
    width: 290px;

    img {
      display: block;
    }

    &:after {
      content: "";
      @include overlay;
      @include basic-transition(opacity, .3s, null, $ease-out-quad);

      .card-event--red & {
        background-color: rgba($color-secondary,.35);
      }

      .card-event--yellow & {
        background-color: rgba($color-tertiary,.35);
      }

      .card-event--green & {
        background-color: rgba($color-primary,.35);
      }

      .card-event:hover & {
        opacity: 0;
      }
    }
  }

  &__info {
    width: 60px;
    font-family: $font-secondary;
    font-size: 24px;
    line-height: 1;
    margin: 0 60px;
    text-align: center;

    @include mq($until: tablet) {
      margin: 0 40px 0 0;
    }
    p {
      margin: 0;
    }
  }

  &__date {
    display: block;
    margin-bottom: 10px;
    @include mq($from: tablet) {
      margin-bottom: 20px;
    }
  }

  &__time {
    display: block;
  }

  &__content {
    flex: 1;
    min-height: 60px;
  }

  &__title {
    margin: 0;
    font-size: 24px;
    line-height: 1;

    a {
      text-decoration: none;
      &:after {
        content: "";
        @include overlay;
      }
    }
  }

  &__place {
    font-family: $font-secondary;
    font-size: 24px;
    line-height: 1;
    font-weight: 700;
    margin-top: 40px;

    @include mq($until: tablet) {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }

  &__text {
    font-family: $font-secondary;
    font-size: 22px;
    line-height: 1.25;
    margin-bottom: 0;

    @include mq($until: tablet) {
      display: none;
    }
  }

}