.c-field,
.c-field-textarea {

  &.is-hidden {
    display: none;
  }
  
  display: block;
  position: relative;

  &__label {
    display: inline-block;
    vertical-align: top;
    color: $color-primary;
    font-weight: 500;
    margin-bottom: 10px;

    abbr {
      color: $color-danger;
      text-decoration: none;
    }

    .error & {
      color: $color-danger;
    }
  }

  &__error {
    color: $color-danger;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 0;
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="tel"],
  input[type="time"],
  textarea {
    display: block;
    background-color: white;
    border: 1px solid transparent;
    padding: 15px;
    font-family: $font-primary;
    color: $color-primary;
    box-sizing: border-box;
    border: 1px solid $color-primary;
    width: 100%;
    
    margin: 0;
    
    outline: none;

    @include basic-transition(border, .25s, null, $ease-out-quart);

    .error & {
      border-color: rgba(red,0.5);
    }
    &:focus {
      border-color: rgba($color-primary,.5);
    }
  }

  &.error > * {
    border-color: $color-danger !important;
  }

  textarea {
    min-height: 200px;
    line-height: 1.5;
    resize: vertical;
  }

  &--info {
    p {
      font-size: 16px;
      color: $color-primary;
      margin: 0;

      em {
        background-color: transparent;
        color: $color-danger;
      }
    }
  }

  &--submit {
    button {
      @include reset-button-style;
      position: relative;
      background-color: $color-tertiary;
      font-family: $font-secondary;
      font-weight: 700;
      font-size: 24px;
      padding: 12px 55px 12px 35px;
      border-radius: 30px;
      @include basic-transition((color,background), .25s, null, $ease-out-quad);
      &:after {
        content: "";
        @include absolute(17px,25px,null,null);
        @include size(20px);
        background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-send' width='24' height='24' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23008E94' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cline x1='10' y1='14' x2='21' y2='3' /%3E%3Cpath d='M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5' /%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        @include basic-transition(transform, .25s, null, $ease-out-quart);
      }
      @include on-event {
        background-color: $color-primary;
        color: white;
        &:after {
          background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-send' width='24' height='24' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23dedc00' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cline x1='10' y1='14' x2='21' y2='3' /%3E%3Cpath d='M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5' /%3E%3C/svg%3E");
          transform: scale(1.2);
        }
      }
    }
  }

}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  margin: 0; 
}