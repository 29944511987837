.cards {

  &__main {
    @include wrap;
  }

  &__head {
    max-width: 640px;
  }

  &__title {

  }

  &__list {

    margin: 40px 0 0;

    @include mq($from: phablet) {
      display: flex;
      flex-flow: row wrap;
      margin: 40px -15px 0;
    }

    &-item {

      @include mq($from: tablet) {
        @for $i from 1 through 2 {
          &:nth-child(2n+#{$i}) {
            transition-delay: $i * 0.15s;
          }
        }
      }

      @include mq($from: desktop) {
        @for $i from 1 through 3 {
          &:nth-child(3n+#{$i}) {
            transition-delay: $i * 0.15s;
          }
        }
      }

      padding: 10px 0;

      @include mq($from: phablet) {
        width: 50%;
        padding: 15px;
      }

      @include mq($from: desktop) {
        width: 33.333%;
      }

    }

  }

  &__button {
    text-align: center;
    margin-top: 50px;

    @include mq($until: tablet) {
      margin-top: 25px;
    }
  }

}