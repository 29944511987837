.card-author {

  position: relative;
  max-width: 860px;

  &__picture {
    position: relative;
    margin-bottom: 30px;
    @include mq($from: tablet) {
      float: left;
      margin-bottom: 0;
    }
    @include size(200px);
    @include img-object-fit;
    border-radius: 50%;
    overflow: hidden;
    img {
      border-radius: 50%;
    }

    @include mq($until: tablet) {
      .authors__list-item:nth-child(odd) & {
        margin: 0 auto 30px;
      }
    }

    &:after {
      content: "";
      @include overlay;
      border-radius: 50%;
      z-index: 1;
      @include basic-transition(opacity, .3s, null, $ease-out-quad);

      .card-author--red & {
        background-color: rgba($color-secondary,.35);
      }

      .card-author--yellow & {
        background-color: rgba($color-tertiary,.35);
      }

      .card-author--green & {
        background-color: rgba($color-primary,.35);
      }

      .card-author:hover & {
        opacity: 0;
      }
    }
  }

  &__content {
    @include mq($from: tablet) {
      margin-left: 250px;
    }
  }

  &__title {
    font-family: $font-primary;
    font-weight: 400;
  }

  &__text {

  }

  &__button {
    font-family: $font-secondary;
    font-size: 22px;
    font-weight: 700;
    a {
      text-decoration: none;
      border-bottom: 1px solid $color-primary;

      &:after {
        content: "";
        @include overlay;
        z-index: 1;
      }
    }
  }

}