blockquote {
  margin: 50px auto 0;
  max-width: 480px;
  position: relative;

  .testimonials & {
    margin: 0 auto 75px;
  }

  @include mq($until: tablet) {
    padding: 0 20px;
  }
  p {
    position: relative;
    padding: 0 25px;
    font-style: italic;
    font-size: 22px;
    line-height: 1.5;
    text-align: center;
    @include mq($until: tablet) {
      font-size: 18px;
    }
    &:before,
    &:after {
      content: "";
      @include size(20px);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    &:before {
      @include absolute(0,null,null,0);
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='14' viewBox='0 0 21 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.69277 0.799998L9.84277 2.2C8.40944 2.3 7.04277 2.7 5.74277 3.4C4.44277 4.1 3.40944 5.18333 2.64277 6.65C2.24277 7.38333 2.04277 8.05 2.04277 8.65C2.04277 8.88333 2.07611 9.03333 2.14277 9.1C2.24277 9.13333 2.30944 9.15 2.34277 9.15C2.44277 9.15 2.57611 8.96666 2.74277 8.6C3.10944 7.73333 3.77611 7.3 4.74277 7.3C5.57611 7.3 6.24277 7.56667 6.74277 8.1C7.27611 8.63333 7.54277 9.28333 7.54277 10.05C7.54277 11.0167 7.22611 11.8167 6.59277 12.45C5.99277 13.05 5.22611 13.35 4.29277 13.35C3.15944 13.35 2.29277 12.9667 1.69277 12.2C1.09277 11.4333 0.792774 10.4833 0.792774 9.35C0.792774 8.41667 1.05944 7.38333 1.59277 6.25C2.49277 4.38333 3.64277 3.06667 5.04277 2.3C6.47611 1.5 8.02611 0.999998 9.69277 0.799998ZM20.4428 0.799998L20.5928 2.2C19.1594 2.3 17.7928 2.7 16.4928 3.4C15.1928 4.1 14.1594 5.18333 13.3928 6.65C12.9928 7.38333 12.7928 8.05 12.7928 8.65C12.7928 8.88333 12.8261 9.03333 12.8928 9.1C12.9928 9.13333 13.0594 9.15 13.0928 9.15C13.1928 9.15 13.3261 8.96666 13.4928 8.6C13.8594 7.73333 14.5261 7.3 15.4928 7.3C16.3261 7.3 16.9928 7.56667 17.4928 8.1C18.0261 8.63333 18.2928 9.28333 18.2928 10.05C18.2928 11.0167 17.9761 11.8167 17.3428 12.45C16.7428 13.05 15.9761 13.35 15.0428 13.35C13.9094 13.35 13.0428 12.9667 12.4428 12.2C11.8428 11.4333 11.5428 10.4833 11.5428 9.35C11.5428 8.41667 11.8094 7.38333 12.3428 6.25C13.2428 4.38333 14.3928 3.06667 15.7928 2.3C17.2261 1.5 18.7761 0.999998 20.4428 0.799998Z' fill='%23008E94'/%3E%3C/svg%3E%0A");
    }
    &:after {
      @include absolute(null,0,0,null);
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='14' viewBox='0 0 21 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.1451 13.45L10.9951 12.05C12.4285 11.95 13.7951 11.55 15.0951 10.85C16.3951 10.1167 17.4285 9.03333 18.1951 7.6C18.5951 6.86667 18.7951 6.2 18.7951 5.6C18.7951 5.36666 18.7618 5.23333 18.6951 5.2C18.6285 5.13333 18.5618 5.1 18.4951 5.1C18.3951 5.1 18.2618 5.28333 18.0951 5.65C17.7285 6.51667 17.0618 6.95 16.0951 6.95C15.2618 6.95 14.5785 6.68333 14.0451 6.15C13.5451 5.61667 13.2951 4.96667 13.2951 4.2C13.2951 3.23333 13.5951 2.45 14.1951 1.85C14.8285 1.21666 15.6118 0.899997 16.5451 0.899997C17.7118 0.899997 18.5785 1.28333 19.1451 2.05C19.7451 2.81666 20.0451 3.76666 20.0451 4.9C20.0451 5.83333 19.7785 6.86667 19.2451 8C18.3451 9.86666 17.1785 11.2 15.7451 12C14.3451 12.7667 12.8118 13.25 11.1451 13.45ZM0.395118 13.45L0.245117 12.05C1.67845 11.95 3.04512 11.55 4.34512 10.85C5.64512 10.1167 6.67845 9.03333 7.44512 7.6C7.84512 6.86667 8.04512 6.2 8.04512 5.6C8.04512 5.36666 8.01178 5.23333 7.94512 5.2C7.87845 5.13333 7.81179 5.1 7.74512 5.1C7.64512 5.1 7.51178 5.28333 7.34512 5.65C6.97845 6.51667 6.31179 6.95 5.34512 6.95C4.51178 6.95 3.82845 6.68333 3.29512 6.15C2.79512 5.61667 2.54512 4.96667 2.54512 4.2C2.54512 3.23333 2.84512 2.45 3.44512 1.85C4.07845 1.21666 4.86178 0.899997 5.79512 0.899997C6.96178 0.899997 7.82845 1.28333 8.39512 2.05C8.99512 2.81666 9.29512 3.76666 9.29512 4.9C9.29512 5.83333 9.02845 6.86667 8.49512 8C7.59512 9.86666 6.42845 11.2 4.99512 12C3.59512 12.7667 2.06178 13.25 0.395118 13.45Z' fill='%23008E94'/%3E%3C/svg%3E%0A");
    }
  }
  cite {
    display: block;
    line-height: 1.5;
    font-style: normal;
    text-align: right;

    a {
      font-style: italic;
      text-decoration: none;
      &:after {
        content: "";
        @include overlay;
      }
      @include on-event {
        text-decoration: underline;
      }
    }

    @include mq($until: tablet) {
      font-size: 16px;
    }
  }
}