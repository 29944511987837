.resource-link {

  position: relative;
  display: flex;
  max-width: 680px;

  &__left {
    width: 70px;
    text-align: center;
  }

  &__icon {
    .icon {
      @include size(70px);
    }
  }

  &__size {
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 1;
    margin: 0;
  }

  &__right {
    flex: 1;
    padding-top: 10px;
    margin-left: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    font-size: 20px;
    line-height: 1;
    margin-bottom: 10px;
    a {
      text-decoration: none;
      &:after {
        content: "";
        @include overlay;
      }
    }
  }

  &__text {
    font-family: $font-secondary;
    font-size: 20px;
    line-height: 1.25;

    p {
      margin: 0;
    }
  }

}