/**
 * Colors
 */

$color-primary:     #008E94;
$color-secondary:   #E91E63;
$color-tertiary:    #DCDC00;

$color-text:        $color-primary;
$color-light:       #f9f9f9;
$color-gray:        #e6e6e6;
$color-border:      #e1e1e1;
$color-link:        $color-text;
$color-title:       $color-tertiary;

$red:               $color-secondary;

$color-danger:      $red;
$color-success:     green;
$color-warning:     orange;
$color-info:        blue;

$body-color:        $color-text;
$body-background:   #FDFFF9;

$button-color:      white;
$button-background: $color-secondary;

$heading-color:     $color-primary;