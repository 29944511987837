figure {
  margin: 40px 0;

  iframe {
    border: 0;
    width: 100%;
    height: 327px;
  }

  figcaption {
    text-align: center;
    padding-top: 5px;
    font-size: rem(16px);
    font-style: italic;
    color: rgba($color-text,.7);
  }

  & > ul {
    @include list-reset;
    display: flex;
    flex-flow: row wrap;
    margin: 0 -5px;

    & > li {
      flex: 1;
      //width: calc(33.333% - 10px);
      //@include img-object-fit(contain);
      overflow: hidden;
      margin: 5px;

      img {
        margin: 0 !important;
      }
    }

  }

  &[data-ratio]:not([data-ratio="auto"]):not([data-crop]) > ul > li {
    @include img-object-fit(contain);
  }

  &[data-crop] > ul > li {
    @include img-object-fit(cover);
  }

  &[data-ratio="1/1"] > ul > li {
    @include aspect-ratio(1,1);
  }

  &[data-ratio="16/9"] > ul > li {
    @include aspect-ratio(16,9);
  }

  &[data-ratio="10/8"] > ul > li {
    @include aspect-ratio(10,8);
  }

  &[data-ratio="21/9"] > ul > li {
    @include aspect-ratio(21,9);
  }

  &[data-ratio="7/5"] > ul > li {
    @include aspect-ratio(7,5);
  }

  &[data-ratio="4/3"] > ul > li {
    @include aspect-ratio(4,3);
  }

  &[data-ratio="5/3"] > ul > li {
    @include aspect-ratio(5,3);
  }

  &[data-ratio="3/2"] > ul > li {
    @include aspect-ratio(3,2);
  }

  &[data-ratio="3/1"] > ul > li {
    @include aspect-ratio(3,1);
  }
}