.testimonials {
  margin-bottom: 50px;

  &__main {
    @include wrap;
  }

  &__list {
    @include mq($from: tablet) {
      display: flex;
      justify-content: center;
      flex-flow: row wrap;
      &-item {
        width: 50%;
        padding-left: 30px;
      }
    }
  }

}