.message {

  padding: 20px;
  margin-bottom: 30px;
  font-size: 16px;

  .footer & {
    margin-top: 20px;
  }

  p {
    margin: 0;
  }

  ol {
    margin: 0;
    padding: 0 0 0 25px;
    li {
      margin: 0;
    }
  }

  &--error {
    border: 1px solid $color-danger;
    background-color: rgba($color-danger,.1);
    color: $color-danger;
  }

  &--success {
    border: 1px solid $color-success;
    background-color: rgba($color-success,.1);
    color: $color-success;
  }

}