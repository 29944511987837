.newsletter {

  .footer & {
    max-width: 300px;
    @include mq($until: desktop) {
      margin: auto;
    }
  }

  .c-field {
    margin-top: 20px;
    @include placeholder(rgba($color-primary,.5));
    input {
      padding: 12px 15px !important;
      font-size: 16px;
    }
  }
  .c-field--submit {
    button {
      font-size: 18px;
      padding: 10px 25px;
      &:after {
        display: none;
      }
    }
  }
}