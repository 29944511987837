.books {

  overflow-x: hidden;
  padding-top: 50px;

  &__main {
    @include wrap(960px);

    .books--alt & {
      @include mq($from: wide) {
        @include wrap(1150px);
      }
    }
  }

  &__list {

    @include mq($from: tablet) {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      /*
      .article + .separator + .books & {
        justify-content: center;
      }
      */
    }

    &-item {

      margin-bottom: 40px;

      @include mq($from: tablet) {
        margin-bottom: 0;
        padding: 0 15px;
        &:nth-child(3n+2) {
          margin-top: 60px;
        }
      }

      @include mq($from: desktop) {
        padding: 0 60px;
      }

      .books--alt & {
        @include mq($from: wide) {
          &:nth-child(3n+2) {
            margin-top: 0;
          }
          padding: 0 15px;
          &:nth-child(5n+2),
          &:nth-child(5n+4) {
            margin-top: 60px;
          }
        }
      }

    }

  }

}