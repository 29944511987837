/**
 * Grid
 */

.row {
  
  display: flex;
  margin: 0 - $default-gutter;

  & > .col {
    flex: 1;
    margin: 0 $default-gutter;
  }

}