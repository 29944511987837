.article {

  .page-about &,
  .page-la-maison & {
    margin: 0;
  }

  &__main {
    @include wrap(960px);

    .page-about &,
    .page-la-maison & {
      @include wrap;
      padding: 100px 0;
      @include mq($until: tablet) {
        padding: 75px 0;
      }
    }

    .page-about .article:not(:last-child) &,
    .page-la-maison .article:not(:last-child) & {
      border-bottom: 1px solid $color-primary;
    }

  }

  &__head {
    margin-bottom: 40px;
    @include mq($from: tablet) {
      margin-bottom: 75px;
    }

    .page-about &,
    .page-la-maison & {
      max-width: 850px;
    }

    @include mq($from: tablet) {
      .article--v1 &,
      .article--v3 & {
        margin-left: 200px;
      }
    }

  }

  &__date {
    margin: 0;
  }

  &__title {
    font-family: $font-primary;
    font-weight: 400;
    font-size: 42px;
    @include mq($from: tablet) {
      font-size: 50px;
    }

    .page-about &,
    .page-la-maison & {
      font-size: 36px;
      @include mq($from: tablet) {
        font-size: 42px;
      }
    }
  }

  &__content {
    img {
      display: block;
      margin: 50px 0;
    }

    .page-about &,
    .page-la-maison & {
      h3 {
        font-size: 24px;
      }
      p:last-child {
        margin-bottom: 0;
      }
    }

    @include mq($from: tablet) {
      .page-about &,
      .page-la-maison & {
        max-width: 580px;
        margin-left: auto;
        margin-right: 100px;
      }

      .article--v1 &,
      .article--v2 &,
      .article-v3 & {
        margin-left: 100px;
        margin-right: 0;
      }
    }

  }

  &__bottom {
    margin-top: 50px;
    @include mq($from: tablet) {
      margin-top: 100px;
    }
    h2 {
      font-family: $font-primary;
      font-weight: 400;
      line-height: 1.5;
      font-style: italic;
      max-width: 720px;
      margin: auto;
      text-align: center;
    }

    h3 {
      font-size: 24px;
    }

    p:last-child {
      margin-bottom: 0;
    }

    @include mq($from: tablet) {
      .article--v1 &,
      .article--v2 &,
      .article-v3 & {
        max-width: 580px;
        margin-left: auto;
        margin-right: 100px;
      }
    }

  }

  &__info {
    background-color: $color-primary;
    color: white;
    padding: 20px 30px;
    text-align: center;
    margin: 0;
  }

}