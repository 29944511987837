.card-member {

  max-width: 480px;

  &__picture {
    position: relative;
    @include size(200px);
    @include img-object-fit;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 50px;

    img {
      border-radius: 50%;
    }

    &:after {
      content: "";
      @include overlay;
      border-radius: 50%;
      z-index: 1;
      @include basic-transition(opacity, .3s, null, $ease-out-quad);

      .card-member--red & {
        background-color: rgba($color-secondary,.35);
      }

      .card-member--yellow & {
        background-color: rgba($color-tertiary,.35);
      }

      .card-member--green & {
        background-color: rgba($color-primary,.35);
      }
    }
  }

  &__title {
    font-family: $font-primary;
    font-weight: 400;
    margin-bottom: 0;
  }

  &__function {
    font-size: 20px;
  }

  &__text {
    margin-top: 25px;
    font-size: 16px;
  }

  &__email {
    font-family: $font-secondary;
    font-weight: 700;
    font-size: 20px;
    text-decoration: none;
    border-bottom: 1px solid $color-primary;
  }

}