.podcasts {
  overflow-x: hidden;

  &__main {
    @include wrap;
  }

  &__list {

    @include mq($from: tablet) {
      display: flex;
      flex-flow: row wrap;
      margin: 0 -50px;

      &-item {
        width: 50%;
        padding: 0 50px;
      }
    }

  }

}