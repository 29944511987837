.book {

  &__main {
    @include wrap(1000px);
  }

  &__columns {
    @include mq($from: tablet) {
      display: flex;
    }
  }

  &__content {
    @include mq($from: tablet) {
      flex: 1;
      margin-left: 100px;
    }
  }

  &__text {
    blockquote + blockquote {
      border-top: 1px solid rgba($color-primary,.3);
      padding-top: 50px;
    }
  }

  &__sidebar {
    margin-top: 50px;
    @include mq($until: tablet) {
      text-align: center;
      margin-bottom: 70px;
    }
    @include mq($from: tablet) {
      margin-top: 0;
      width: 320px;
    }
  }

  &__picture {
    position: relative;
    width: 200px;
    border: 1px solid rgba($color-primary,.3);

    @include mq($until: tablet) {
      margin: auto;
    }

    img {
      display: block;
    }
  }

  &__price {
    font-family: $font-secondary;
    font-size: 18px;
    margin: 15px 0;

    input {
      margin-right: 5px;
      transform: translateY(1px);

      &.is-hidden {
        display: none;
        & + strong {
          width: 200px;
          display: block;
          text-align: center;
        }
      }
    }

    label {
      display: block;
    }

    @include mq($until: tablet) {
      margin: 15px auto;
    }

    p {
      margin: 0;
    }
  }

  &__button {
    .book__button-format1,
    .book__button-format2,
    .book__button-format3 {
      display: none;
      &.is-active-button {
        display: block;
      }
    }
    .btn {
      width: 100%;
      max-width: 200px;
    }
    @include mq($until: tablet) {
      text-align: center;
    }
  }

  &__head {
    margin: 30px 0 20px;
  }

  &__title {
    font-size: 22px;
    margin: 0;
  }

  &__author {
    font-size: 22px;
    font-weight: 400;
    margin: 0;

    a {
      text-decoration: none;
    }
  }

  &__infos {
    font-family: $font-secondary;
    font-size: 20px;
    line-height: 1.25;
    font-weight: 300;

    p {
      margin: 0;
    }
  }

  &__links,
  &__social,
  &__events,
  &__resources {
    margin-top: 40px;
    ul {
      @include list-reset;
      li {
        margin: 0;
      }
    }
  }

  &__links {
    margin-top: 20px;
    font-family: $font-secondary;
    font-size: 20px;
    font-weight: 700;
    a {
      text-decoration: none;
      border-bottom: 1px solid $color-primary;
    }
  }

  &__events,
  &__resources {
    ul {
      li {
        &:not(:first-child) {
          margin-top: 25px;
        }
      }
    }
  }

  &__resources {
    text-align: left;
  }

  &__content {
    h3 {
      font-family: $font-primary;
      font-weight: 300;
      text-align: center;
      line-height: 1.5;
      margin-bottom: 80px;
      font-style: italic;

      @include mq($until: tablet) {
        margin-bottom: 40px;
      }
    }

  }

}