.hero {

  margin: 0;
  padding-top: 100px;

  &__main {
    @include wrap(1100px);
    text-align: center;
  }

  &__title {
    font-family: $font-primary;
    font-weight: 400;
    font-style: italic;
    font-size: 28px;
    line-height: 1.35;
    margin-bottom: 15px;

    @include mq($from: tablet) {
      font-size: 36px;
    }

    @include mq($from: desktop) {
      font-size: 48px;
    }
  }

  &__text {
    @include mq($from: desktop) {
      font-size: 22px;
    }
  }

  &__button {
    margin-top: 40px;
  }
  
}