.resources {

  &__main {
    @include wrap(980px);
  }

  &__head {
    text-align: center;
    margin-bottom: 50px;

    @include mq($from: tablet) {
      margin-bottom: 75px;
    }
  }

  &__title {
    font-family: $font-primary;
    font-weight: 400;
  }

  &__list {

    &-item {
      margin-bottom: 30px;
    }

  }

}