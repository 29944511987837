.text-img {

  &__main {
    @include wrap;
  }

  &__columns {

    @include mq($from: tablet) {
      display: flex;
      &-left,
      &-right {
        width: 50%;
      }
    }

    @include mq($until: tablet) {
      &-left {
        margin-bottom: 30px;
      }
    }

    &-left {

      @include mq($from: tablet) {
        padding-right: 25px;
      }

      @include mq($from: desktop) {
        padding-right: 50px;
      }

      .text-img + .text-img & {

        @include mq($from: tablet) {
          padding-right: 0;
          padding-left: 25px;
        }

        @include mq($from: desktop) {
          padding-left: 50px;
        }
      }
    }

    &-right {

      @include mq($from: tablet) {
        padding-left: 25px;
      }

      @include mq($from: desktop) {
        padding-left: 50px;
      }

      .text-img + .text-img & {

        @include mq($from: tablet) {
          order: -1;
          padding-left: 0;
          padding-right: 25px;
        }

        @include mq($from: desktop) {
          padding-right: 50px;
        }

      }
      
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__picture {
    @include aspect-ratio(4,3);
    @include img-object-fit;
    background-color: $color-gray;
  }

  &__content {

  }

}