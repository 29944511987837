.separator {

  margin-bottom: 50px;

  & + * {
    margin-top: 50px;
  }

  .breadcrumb + & {
    margin-top: 0;
  }

  &__main {
    @include wrap;
    text-align: center;
    position: relative;
  }

  &__title {
    font-size: 24px;
    display: inline-block;
    background-color: $body-background;
    padding: 0 80px;
    margin-bottom: 15px;

    @include mq($until: mobile) {
      padding: 0 30px;
    }

    &:before {
      content: "";
      @include absolute(15px,0,null,0);
      z-index: -1;
      height: 1px;
      background-color: $color-primary;
    }
  }

  &__text {
    max-width: 380px;
    margin: auto;
    font-family: $font-secondary;
    font-size: 20px;
    line-height: 1.25;
    p {
      margin: 0;
    }
  }

}