/**
 * Reset button style
 * Usage
   @include reset-button-style;
 */
@mixin reset-button-style {
  background: none;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button; /* for input */
  -webkit-user-select: none; /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  outline: none;
  vertical-align: top;
  box-shadow: none;

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}