.checkbox {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    padding: 0 0 0 25px;
    font-size: 16px;
    color: $color-primary;
    line-height: 1.5;
    text-align: left;

    abbr {
      color: $color-danger;
      text-decoration: none;
    }

    p {
      margin: 0;
    }

    a {
      color: white;
      @include on-event {
        color: rgba(white,.7);
      }
    }

    .error & {
      color: $color-danger;
    }
  }

  & + label:before {
    content: '';
    @include absolute(5px,null,null,0);
    @include size(14px);
    border: 1px solid $color-primary;
    background-color: white;
  }

  & + label:after {
    content: '';
    @include absolute(7px,null,null,2px);
    @include size(10px);
    background: $color-primary;
    transform: scale(0);
    opacity: 0;

    transition: .3s transform $ease-in-out-back, .3s opacity $ease-in-out-back;
  }
  
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:before {
    border-color: $color-primary;
  }

  &:checked + label:after {
    transform: scale(1);
    opacity: 1;
  }
}