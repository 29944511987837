.sticky-nav {

  position: sticky;
  top: 72px;
  margin: 10px 0;
  padding: 15px 0;
  background-color: rgba($body-background,.7);
  backdrop-filter: blur(10px);
  z-index: 10;

  @include mq($until: tablet) {
    margin: 0;
  }

  @include mq($until: desktop) {
    top: 71px;
    overflow-x: scroll;
  }

  &__main {
    @include wrap;

    @include mq($until: desktop) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  &__list {
    font-size: 22px;

    ul {
      @include list-reset;
      display: flex;

      @include mq($from: desktop) {
        justify-content: center;
      }
    }
    li {
      margin: 0;
      position: relative;
      &:not(:last-child) {
        &:after {
          content: "";
          @include absolute(0,0,0,null);
          width: 1px;
          background-color: rgba($color-primary,.7);
        }
      }
    }

    a,
    span {
      display: block;
      text-decoration: none;
      @include size(37px,30px);
      text-align: center;
      line-height: 30px;

      @include mq($from: 1200px) {
        width: 45px;
      }
    }

    span {
      opacity: 0.2;
    }
  }

}