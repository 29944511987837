.pagination {
  margin-top: 50px;

  ul {
    @include list-reset;
    text-align: center;

    li {
      display: inline-block;
      vertical-align: top;
      margin: 0;
    }
  }

  &__link,
  &__arrow {
    display: block;
    @include size(40px);
    line-height: 40px;
    text-align: center;
    text-decoration: none;
  }

  &__link {
    border: 1px solid $color-primary;

    &[aria-current="page"] {
      background-color: $color-primary;
      color: white;
      pointer-events: none;
    }
  }

  &__arrow {
    font-size: 36px;
    line-height: 34px;
  }

}