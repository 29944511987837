/**
 * Header Logo
 */

.logo {
  
  img {
    display: block;
    width: auto;
    height: 45px;
    @include mq($from: 1100px) {
      height: 50px;
    }

    @include mq($until: mobile) {
      height: 35px;
    }

    @include basic-transition(height, .25s, null, $ease-out-quart);

    .is-small & {
      height: 45px;

      @include mq($until: mobile) {
        height: 35px;
      }
    }
  }
  
}