// MENU TOGGLE
.nav-btn {

  @include reset-button-style;
  @include size(50px);
  position: relative;
  border-radius: 10px;
  z-index: 101;
}

.nav-toggle {
  position: relative;
  display: inline-block;
  vertical-align: top;
  @include size(24px,20px);
  transform-origin: 50%;
  transition: transform .3s $ease-out-quint;
  cursor: pointer;

  b {

    position: absolute;
    left: 0;
    top: 50%;
    display: block;
    width: 100%;
    height: 1px;
    margin: -2px 0 0;
    background: $color-primary;

    &.top {
      transform-origin: 50%;
      transform: translate(0, -7px);
      transition: transform .2s .1s $ease-out-quint, background .2s .1s $ease-out-quint;
    }

    &.middle {
      transform: translate(0, 2px);
      transition: opacity .2s .1s $ease-out-quint, background .2s .1s $ease-out-quint;
    }

    &.bottom {
      transform-origin: 50%;
      transform: translate(0, 11px);
      transition: transform .2s .1s $ease-out-quint, background .2s .1s $ease-out-quint;
    }

  }
  
  .open-menu & {
    transform: rotate(180deg);
    b.top {
      transform: translate(0, 1px) rotate(-45deg);
    }
    b.middle {
      opacity: 0;
    }
    b.bottom {
      transform: translate(0, 1px) rotate(45deg);
    }
  }

}