.authors {

  &__main {
    @include wrap;
  }

  &__list {

    &-item {
      margin: 100px 0;

      @include mq($from: tablet) {
        &:nth-child(5n+2) { margin-left: 50px; }
        &:nth-child(5n+3) { margin-left: 100px; }
        &:nth-child(5n+4) { margin-left: 100px; }
        &:nth-child(5n+5) { margin-left: 50px; }
      }
    }

  }

}