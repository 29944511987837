.c-form {

  @include placeholder(rgba($color-primary,.5));
  
  &__fieldset {
    margin: 0;
    padding: 0;
    border: 0;

    &-fields {

    }
  }

  .c-field {
    margin: 25px 0;
    @include margin-first-last(vertical);

    &--submit {
      margin-top: 35px;
    }

    &.is-hidden {
      display: none;
    } 
  }

}

.honeypot {
  position: absolute;
  left: -9999px;
}